const storage = {

    data() {
        return {
          
        }
    },
    methods:{

        setToken(token){
            localStorage.setItem('token', token)
        },
        clearToken(){
            localStorage.removeItem('token')
        },
        getToken(){
            return localStorage.getItem('token')
        },

        setId(id){
            localStorage.setItem('id', id)
        },
        clearId(){
            localStorage.removeItem('id')
        },
        getId(){
            return localStorage.getItem('id')
        },

        setLogin(login){
            localStorage.setItem('login', login)
        },
        clearLogin(){
            localStorage.removeItem('login')
        },
        getLogin(){
            return localStorage.getItem('login')
        },
        
        setNome(nome){
            localStorage.setItem('nome', nome)
        },
        clearNome(){
            localStorage.removeItem('nome')
        },
        getNome(){
            return localStorage.getItem('nome')
        },
        
        setCpf(cpf){
            localStorage.setItem('cpf', cpf)
        },
        clearCpf(){
            localStorage.removeItem('cpf')
        },
        getCpf(){
            return localStorage.getItem('cpf')
        },

        setEmail(email){
            localStorage.setItem('email', email)
        },
        clearEmail(){
            localStorage.removeItem('email')
        },
        getEmail(){
            return localStorage.getItem('email')
        },
        
        setEmpresa(empresa){
            localStorage.setItem('empresa', empresa)
        },
        clearEmpresa(){
            localStorage.removeItem('empresa')
        },
        getEmpresa(){
            return localStorage.getItem('empresa')
        },

        setUser(user){
            localStorage.setItem('user', user)
        },
        clearUser(user){
            localStorage.removeItem('user', user)
        },
        getUser(){
            let user = localStorage.getItem('user')
            return user
        },
        setRole(role){
            localStorage.setItem('role', role)
        },
        clearRole(){
            localStorage.removeItem('role')
        },
        getRole(){
            return localStorage.getItem('role')
        }
    }
}

export default storage