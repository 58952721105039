<template>
  <div>
    <title-bar :title-stack="titleStack" />
    <hero-bar>
      Perfil
      <router-link slot="right" to="/" class="button">
        Dashboard
      </router-link>
    </hero-bar>
    <section class="section is-main-section">
      <tiles-block>
        <!--<profile-update-form class="tile is-child" />-->
        <card-component title="Perfil" icon="account" class="tile is-child">
          <!--<user-avatar class="image has-max-width is-aligned-center" />-->
          <b-field horizontal style="margin-left: -80px;">
            <b-field label="Empresa">
              <b-input :value="usuario.empresa" custom-class="is-static" readonly />
            </b-field>
          </b-field>
          <hr>
          <b-field horizontal style="margin-left: -80px;">
            <b-field label="Nome" >
              <b-input :value="usuario.nome" custom-class="is-static" readonly />
            </b-field>
          </b-field>
          <hr>
          <b-field horizontal style="margin-left: -80px;">
            <b-field label="CPF">
              <b-input :value="usuario.cpf" custom-class="is-static" readonly />
            </b-field>
          </b-field>
          <hr>
          <b-field horizontal >
            <b-field label="E-mail" style="margin-left: -70px;">
              <b-input :value="usuario.email" custom-class="is-static" readonly />
            </b-field>
          </b-field>
          <hr>
          <b-field horizontal>
            <b-field label="Perfil" style="margin-left: -70px;">
              <b-input :value="usuario.perfil" custom-class="is-static"/>
            </b-field>
          </b-field>
        </card-component>
        <password-update-form class="tile is-child"/>
      </tiles-block>
    </section>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import CardComponent from '@/components/CardComponent.vue'
import TitleBar from '@/components/TitleBar.vue'
import HeroBar from '@/components/HeroBar.vue'
import ProfileUpdateForm from '@/components/ProfileUpdateForm.vue'
import PasswordUpdateForm from '@/components/PasswordUpdateForm.vue'
import TilesBlock from '@/components/TilesBlock.vue'
import UserAvatar from '@/components/UserAvatar.vue'
import storage from '@/mixins/storage'

export default defineComponent({
  name: 'Perfil',
  components: {
    UserAvatar,
    TilesBlock,
    PasswordUpdateForm,
    ProfileUpdateForm,
    HeroBar,
    TitleBar,
    CardComponent
  },
  data() {
    return {
      titleStack: ['Admin', 'Perfil'],
      usuario: {
        nome: null,
        email: null,
        perfil: null,
        login: null,
        empresa: null,
        cpf: null
      }
    }
  },
  mounted() {
    this.montarUsuario(this.usuario)
  },
  methods: {
    montarUsuario(user) {
      user.nome = this.formatarEntrada(storage.methods.getNome())
      user.email = this.formatarEntrada(storage.methods.getEmail())
      user.perfil = this.formatarEntrada(storage.methods.getUser())
      user.login = this.formatarEntrada(storage.methods.getLogin())
      user.empresa = this.formatarEntrada(storage.methods.getEmpresa())
      user.cpf = this.formatarEntrada(storage.methods.getCpf())
    },
    formatarEntrada(user){
      if(user == "null" || !user){
        return "NÃO INFORMADO"
      }
      return user.toUpperCase()
    }
  },
  computed: {
  }
})
</script>
