<template>
    <b-modal
      :active.sync="isModalActive"
      has-modal-card
    >
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">
            <b>Deletar Veículo</b>
          </p>
          <button
            type="button"
            class="delete"
            @click="cancel"
          />
        </header>
        <section class="modal-card-body">
          <p>
            Deseja realmente remover o veículo <b>{{trashObjectName}}</b> ?
          </p>
          <p>Esta ação não poderá ser desfeita!</p>
        </section>
        <footer class="modal-card-foot">
          <b-button
            native-type="button"
            type="is-danger"
            outlined
            @click="cancel"
          >
            Cancelar
          </b-button>
          <b-button
            type="is-danger"
            @click="confirm"
          >
            Deletar
          </b-button>
        </footer>
      </div>
    </b-modal>
  </template>
  
  <script>
  import { defineComponent } from 'vue'
  
  export default defineComponent({
    name: 'ModalDeleteEmpresa',
    props: {
      isActive: Boolean,
      trashObjectName: {
        type: String,
        default: null,
      }
    },
    emits: ['cancel', 'confirm'],
    computed: {
      isModalActive: {
        get: function () {
          return this.isActive
        },
        set: function (value) {
          if (!value) {
            this.cancel()
          }
        }
      }
    },
    methods: {
      confirm () {
        const id = this.trashObjectId        
        this.$emit('confirm', id)
      },

      cancel () {
        this.$emit('cancel')
      }
    }
  })
  </script>
  