import Vue from 'vue'
import VueRouter from 'vue-router'
import storage from '@/mixins/storage'
import Login from '@/views/full-page/LoginView.vue'
import ResetSenha from '@/views/full-page/ResetSenha.vue'
import RecuperarSenha from '@/views/full-page/RecuperarSenha.vue'
import Perfil from '@/views/ProfileView.vue'
import HomeView from '@/views/HomeView.vue'
import ListAtivacoes from '@/views/veiculos/ListAtivacoes.vue'
import ConsultarPlaca from '@/views/veiculos/ConsultarPlaca.vue'
import ListVeiculo from '@/views/veiculos/ListVeiculo'
import FormVeiculo from '@/views/veiculos/FormVeiculo.vue'
import ListPessoa from '@/views/pessoas/ListPessoa'
import FormPessoa from '@/views/pessoas/FormPessoa'
import ListUsuario from '@/views/configuracoes/ListUsuario.vue'
import FormUsuario from '@/views/configuracoes/FormUsuario.vue'
import ListExterno from '@/views/externos/ListExterno.vue'
import FormExterno from '@/views/externos/FormExterno.vue'
import FormDocs from '@/views/pessoas/FormDocs.vue'
import ListAutorizacao from '@/views/autorizacoes/ListAutorizacao.vue'
import FormAutorizacao from '@/views/autorizacoes/FormAutorizacao.vue'
import ListPerfis from '@/views/configuracoes/ListPerfis.vue'
import FormPerfil from '@/views/configuracoes/FormPerfil.vue'

Vue.use(VueRouter)

const routes = [
  { meta: { title: 'Dashboard', requiresAuth: true }, path: '/', name: 'home', component: HomeView },
  
  //Veículos Router
  { meta: { title: 'Veículos', requiresAuth: true }, path: '/veiculos', name: 'ListVeiculo', component: ListVeiculo },
  { meta: { title: 'Adicionar Veículo', requiresAuth: true }, path: '/adicionarVeiculo', name: 'FormVeiculo', component: FormVeiculo },
  { meta: { title: 'Alterar Veículo', requiresAuth: true }, path: '/alterarVeiculo/:id', name: 'FormVeiculo.edit', component: FormVeiculo, props: true },
  { meta: { title: 'Ativações', requiresAuth: true }, path: '/ativacoes', name: 'Ativações', component: ListAtivacoes },
  { meta: { title: 'Consultar Placa', requiresAuth: true }, path: '/ConsultarPlaca', name: 'ConsultarPlaca', component: ConsultarPlaca },
  
  //Pessoas Router
  { meta: { title: 'Pessoas', requiresAuth: true }, path: '/pessoas', name: 'ListPessoa', component: ListPessoa },
  { meta: { title: 'Adicionar Pessoa', requiresAuth: true }, path: '/adicionarPessoa', name: 'FormPessoa', component: FormPessoa },
  { meta: { title: 'Alterar Pessoa', requiresAuth: true }, path: '/alterarPessoa/:id', name: 'FormPessoa.edit', component: FormPessoa, props: true },
  { meta: { title: 'Documentos', requiresAuth: true }, path: '/documentos/:idPessoa', name: 'FormDocs', component: FormDocs },

  //Usuario Router
  { meta: { title: 'Usuários', requiresAuth: true }, path: '/usuarios', name: 'ListUsuario', component: ListUsuario },
  { meta: { title: 'Adicionar Usuário', requiresAuth: true }, path: '/adicionarUsuario', name: 'FormUsuario', component: FormUsuario },
  { meta: { title: 'Alterar Usuário', requiresAuth: true }, path: '/alterarUsuario/:id', name: 'FormUsuario.edit', component: FormUsuario, props: true },

  //Externos Router
  { meta: { title: 'Externos', requiresAuth: true }, path: '/externos', name: 'ListExterno', component: ListExterno },
  { meta: { title: 'Adicionar Externo', requiresAuth: true }, path: '/adicionarExterno', name: 'FormExterno', component: FormExterno },
  { meta: { title: 'Alterar Externo', requiresAuth: true }, path: '/alterarExterno/:id', name: 'FormExterno.edit', component: FormExterno, props: true },

  //Autorizacao Router
  { meta: { title: 'Autorização', requiresAuth: true }, path: '/autorizacoes', name: 'ListAutorizacao', component: ListAutorizacao },
  { meta: { title: 'Adicionar Autorização', requiresAuth: true }, path: '/formAutorizacao', name: 'FormAutorizacao', component: FormAutorizacao },
  { meta: { title: 'Autorização', requiresAuth: true }, path: '/formAutorizacao/:id', name: 'FormAutorizacao.edit', component: FormAutorizacao, props: true },

  //Perfil Router
  { meta: { title: 'Profile', requiresAuth: true }, path: '/perfil', name: 'Perfil',  component: Perfil },
  { meta: { title: 'Gerenciar Perfis', requiresAuth: true }, path: '/gerenciarPerfis', name: 'ListPerfis',  component: ListPerfis },
  { meta: { title: 'Adicionar Perfil', requiresAuth: true }, path: '/formPerfil', name: 'FormPerfil',  component: FormPerfil },
  { meta: { title: 'Atualizar Perfil', requiresAuth: true }, path: '/formPerfil/:id', name: 'FormPerfil.edit',  component: FormPerfil, props: true },

  //Login Router
  {
    path: '/full-page',
    component: () => import(/* webpackChunkName: "full-page" */ '@/views/FullPageView.vue'),
    children: [
      { meta: { title: 'Login', requiresAuth: false }, path: '/login', name: 'login', component: Login },
      { meta: { title: 'Recuperar Senha', requiresAuth: false }, path: '/recuperarSenha', name: 'RecuperarSenha', component: RecuperarSenha },
      { meta: { title: 'Resetar Senha', requiresAuth: false }, path: '/resetSenha', name: 'ResetSenha',  component: ResetSenha },
    ]
  },
  {
    meta: {
      title: 'Tables'
    },
    path: '/tables',
    name: 'tables',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "tables" */ '@/views/TablesView.vue')
  },
  {
    meta: {
      title: 'Forms'
    },
    path: '/forms',
    name: 'forms',
    component: () => import(/* webpackChunkName: "forms" */ '@/views/FormsView.vue')
  },
  {
    meta: {
      title: 'New Client'
    },
    path: '/client/new',
    name: 'client.new',
    component: () => import(/* webpackChunkName: "client-form" */ '@/views/ClientFormView.vue')
  },
  {
    meta: {
      title: 'Edit Client'
    },
    path: '/client/:id',
    name: 'client.edit',
    component: () => import(/* webpackChunkName: "client-form" */ '@/views/ClientFormView.vue'),
    props: true
  },

]

const router = new VueRouter({
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!storage.methods.getToken()) {
      next({
        path: "/login",
        query: { redirect: to.fullPath }
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router

export function useRouter() {
  return router
}
