import Vue from 'vue';

export default {

  getEmpresas(page, filtro) {
    if(page == undefined){
      page = 0
    }
    
    let requisicao = `empresas?page=${page}`

    if(filtro){
      requisicao += `${filtro}`
    }
    return Vue.prototype.$http.get(requisicao);
    
  },
  
  getEmpresa(id){
    return Vue.prototype.$http.get(`empresas/${id}`);
  },

  postEmpresa(empresa){
    return Vue.prototype.$http.post('/empresas', empresa)
  },
  
  putEmpresa(id, empresa){
    return Vue.prototype.$http.put(`empresas/${id}`, empresa);
  },

  deleteEmpresa(id){
    return Vue.prototype.$http.delete(`empresas/${id}`);
  },

  postVincularTerminal(idPatio, idTerminal){
    return Vue.prototype.$http.post(`empresas/${idPatio}/terminais/${idTerminal}`, )
  },

  deleteVinculoTerminal(idPatio, idTerminal){
    return Vue.prototype.$http.delete(`empresas/${idPatio}/terminais/${idTerminal}`, )
  },
};
