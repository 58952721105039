<template>
    <div>
        <title-bar :title-stack="titleStack" />
        <div class="card">
            <header class="card-header">
                <p class="card-header-title"><span class="icon"><i class="mdi mdi-ballot default"></i></span> Ativações
                </p>

                <b-field style="margin-right: 1.5rem; margin-top: 1rem; margin-bottom: 1rem;">
                    <b-switch v-model="monitorMode" passive-type='is-dark' type='is-info' :left-label="true"
                        @input="trocarModo()">
                        Modo Monitoramento:
                    </b-switch>
                </b-field>
            </header>

            <div class="card-content" v-if="!monitorMode">
                <form @submit.prevent="buscar()" id="form">
                    <b-field horizontal style="margin-left: -100px;">
                        <b-field label="Placa">
                            <b-input v-model="filtro.placa" icon="card-bulleted-outline" placeholder="Digite a Placa"
                                name="placa" />
                        </b-field>
                        <b-field label="Data Inicio Acesso">
                            <b-datepicker v-model="dataInicio" placeholder="Data Fim acesso" icon="calendar-today"
                                trap-focus>
                            </b-datepicker>
                        </b-field>
                        <b-field label="Data Fim Acesso">
                            <b-datepicker v-model="dataFim" placeholder="Data Fim acesso" icon="calendar-today" trap-focus>
                            </b-datepicker>
                        </b-field>
                        <b-field grouped>
                            <div class="control" style="margin-top: 32px;">
                                <b-button native-type="submit" type="is-info">
                                    Buscar
                                </b-button>
                            </div>
                        </b-field>
                    </b-field>
                </form>
            </div>

            <div v-else class="card-content">
                <div class="columns is-centered">
                    <b-field>
                        <form @submit.prevent="buscar()">
                            <b-field label="Portão">
                                <b-select v-model="filtro.codigocamera" icon="card-bulleted-outline"
                                    @input="iniciarRequisicoes()">
                                    <option v-for="(item, index) in listCamera" :key="index" :value="item.value">
                                        {{ item.label }}
                                    </option>
                                </b-select>
                            </b-field>
                        </form>

                        <b-field>
                            <div class="control" style="margin-top: 32px; margin-left: 2rem;">
                                <b-button type="is-info" @click.prevent="controleModal(true)">
                                    Consultar Placa
                                </b-button>
                            </div>
                        </b-field>

                        <ModalConsultarPlaca :is-active="isModalActive" @confirm="abrirCancela"
                            @cancel="controleModal(false)" />

                    </b-field>
                </div>

                <br>

                <div v-if="mostrarMonitor">
                    <div style="width: 100%;" class="columns is-centered" v-if="monitor">
                        <b-field class="column is-half box">
                            <b-message has-icon icon="car" title="Monitoramento" :closable="false"
                                :type="(monitor.verificarCadastro) ? 'is-success' : 'is-danger'">
                                <div>
                                    <span>Placa: </span>
                                    <span style="font-size: 1.5rem; font-weight: bold; letter-spacing: 0.2rem;"> {{
                                        monitor.placa }} </span>
                                    <br>
                                    <span>Status: <b>{{ monitor.verificarCadastro ? 'Cadastrado' : 'Não Cadastrado'
                                    }}</b></span>
                                    <br>
                                    <span>Data de Entrada: <b>{{ maskDate(monitor.data) }}</b></span>
                                    <br>
                                    <span>Câmera: <b>{{ monitor.camera }}</b></span>
                                    <br>
                                    <span style="font-size: 0.8rem"><i class="mdi mdi-loading mdi-spin mr-1"></i> Última Atualização: {{ horarioRequest }} - Atualização
                                        a cada 2
                                        minutos.</span>
                                </div>
                            </b-message>
                        </b-field>
                    </div>

                    <div style="width: 100%;" class="columns is-centered" v-else>
                        <b-field class="column is-half box">
                            <b-message has-icon icon="car" title="Monitoramento" :closable="false">
                                <div>
                                    <span>Sem ativação nos últimos 2 minutos...</span>
                                    <br>
                                    <span class="mdi mdi-loading mdi-spin" style="font-size: 0.8rem">Última Atualização: {{ horarioRequest }} - Atualização
                                        a cada 2
                                        minutos.</span>
                                </div>
                            </b-message>
                        </b-field>
                    </div>
                </div>


            </div>

            <section class="section is-main-section">
                <card-component class="has-table has-mobile-sort-spaced">
                    <b-table :checked-rows.sync="checkedRows" :data="ativacoesTable" striped hoverable>

                        <b-table-column v-slot="ativacao" label="Placa" field="placa" sortable>
                            {{ ativacao.row.placa }}
                        </b-table-column>
                        <b-table-column v-slot="ativacao" label="Data de Entrada" field="placa" sortable>
                            {{ maskDate(ativacao.row.dataEntrada) }}
                        </b-table-column>
                        <b-table-column v-slot="ativacao" label="Câmera" field="camera" sortable>
                            {{ ativacao.row.camera }}
                        </b-table-column>

                        <section slot="empty" class="section">
                            <div class="content has-text-grey has-text-centered">
                                <p>
                                    <b-icon icon="emoticon-sad" size="is-large" />
                                </p>
                                <p>Não há dados&hellip;</p>
                            </div>
                        </section>
                    </b-table>
                    <br>
                    <section style="margin:15px;">
                        <b-pagination :total="pageable.size" v-model="pageable.current" :range-before="3" :range-after="1"
                            :order="''" :size="''" :simple="false" :rounded="false" :per-page="15"
                            :icon-prev="'chevron-left'" :icon-next="'chevron-right'" aria-next-label="Next page"
                            aria-previous-label="Previous page" aria-page-label="Page" aria-current-label="Current page"
                            :page-input="false" :page-input-position="''" :debounce-page-input="''">

                            <template #default="props">
                                <b-pagination-button :page="props.page" :id="`page${props.page.number}`" tag="router-link"
                                    :to="`/ativacoes?page=${props.page.number}&${$util.formatQueryUrl($route.query)}`">
                                    {{ props.page.number }}
                                </b-pagination-button>
                            </template>


                            <template #previous="props">
                                <b-pagination-button :page="props.page" tag="router-link"
                                    :to="`/ativacoes?page=${props.page.number}&${$util.formatQueryUrl($route.query)}`">
                                    Anterior
                                </b-pagination-button>
                            </template>

                            <template #next="props">
                                <b-pagination-button :page="props.page" tag="router-link"
                                    :to="`/ativacoes?page=${props.page.number}&${$util.formatQueryUrl($route.query)}`">
                                    Próximo
                                </b-pagination-button>
                            </template>
                        </b-pagination>
                        <template>
                            <div>
                                <b>Total de Registros: </b> {{ totalElements }}
                            </div>
                        </template>
                    </section>
                </card-component>
            </section>

        </div>
    </div>
</template>


<script>
import { defineComponent } from 'vue'
import TitleBar from '@/components/TitleBar.vue'
import CardComponent from '@/components/CardComponent.vue'
import AtivacaoService from '@/services/AtivacoesService.js'
import ModalConsultarPlaca from '@/components/ModalConsultarPlaca.vue'

export default defineComponent({
    name: "ListAtivacoes",
    components: {
        TitleBar,
        CardComponent,
        ModalConsultarPlaca
    },
    data() {
        return {
            titleStack: ['SECAPI', 'Veiculos', 'Ativações'],
            filtro: {
                placa: null,
                dataInicio: null,
                dataFim: null,
                camera: null
            },
            ativacoes: [],
            pageable: {
                current: this.$route.query.page,
                size: 0,
            },
            totalElements: null,
            totalPages: null,
            size: null,
            checkedRows: [],
            dataInicio: null,
            dataFim: null,
            monitorMode: true,
            listCamera: [
                {
                    label: "FUNCIONÁRIOS", value: "HOC0216"
                },
                {
                    label: "VISITANTES", value: "HOC0214"
                },
                {
                    label: "MOTOCICLETAS", value: "HOC0206"
                },
            ],
            monitor: [],
            intervalId: null,
            isModalActive: false,
            horarioRequest: null,
            mostrarMonitor: false,
            cameraSelecionada: null,
            maquina: null
        }
    },
    methods: {
        controleModal(control) {
            this.isModalActive = control
        },


        abrirCancela() {
            if (this.maquina) {
                let cancela = this.listCamera.find(item => item.value === this.cameraSelecionada)
                this.$buefy.dialog.confirm({
                    title: 'Abrir a Cancela',
                    message: `Tem certeza que deseja <b>abrir</b> a cancela <br> <b>${this.cameraSelecionada}</b> - <b>${cancela.label}</b> ?`,
                    confirmText: 'Abrir',
                    cancelText: 'Cancelar',
                    type: 'is-info',
                    hasIcon: true,
                    onConfirm: () => this.ativarCancela(this.maquina)
                })
            } else {
                this.$buefy.toast.open({
                    message: 'Por favor, selecione um portão na tela de Monitoramento!',
                    type: 'is-danger',
                    duration: 4000
                })
            }

        },

        ativarCancela(maquina) {
            AtivacaoService.ativarCancela(maquina)
                .then((result) => {
                    this.$buefy.toast.open({
                        message: "Ação concluída com sucesso!",
                        type: 'is-success'
                    })
                })
                .catch((error) => {
                    if (error.response) {
                        console.log(error.response)
                        this.$buefy.toast.open({
                            message: "Não foi possível concluir a ação.",
                            type: 'is-danger'
                        })
                    }
                })
        },

        retornaMonitoramento() {
            this.monitor = null
            this.horarioRequest = new Date().toLocaleTimeString();
            AtivacaoService.getMonitoramento(this.cameraSelecionada)
                .then((result) => {
                    this.monitor = result.data
                })
                .catch((error) => {
                    this.$buefy.toast.open({
                        message: `Não foi possível retornar o Monitoramento`,
                        position: 'is-bottom',
                        type: 'is-danger'
                    })
                    if (error.response) {
                        console.log(error.response)
                    }
                })
        },

        setarMaquina(item) {
            switch (item) {
                case "HOC0216": // FUNCIONARIOS CAMERA
                    return "HAN0230" // FUNCIONARIOS CANCELA

                case "HOC0214": // VISITANTES
                    return "HAN0232" // VISITANTES

                case "HOC0206": // MOTOCICLETAS
                    return "HAN0222" // MOTOCICLETAS

                default: return null
            }
        },

        iniciarRequisicoes() {
            this.cameraSelecionada = this.filtro.codigocamera
            this.maquina = this.setarMaquina(this.cameraSelecionada)
            this.pararRequisicoes()
            this.mostrarMonitor = true
            this.buscar()
            this.retornaMonitoramento()
            this.intervalId = setInterval(this.retornaMonitoramento, 120000);
        },
        pararRequisicoes() {
            clearInterval(this.intervalId);
        },

        trocarModo(item) {
            if (this.monitorMode) {
                this.filtro.codigocamera = null
            }
            this.maquina = null
            this.cameraSelecionada = null
            this.filtro.placa = null
            this.dataFim = null
            this.dataInicio = null
            this.mostrarMonitor = false
            this.$router.push('/ativacoes?page=1&orderBy=id,DESC').catch(() => { })
        },
        buscar() {
            if (this.filtro) {
                if (this.dataInicio) {
                    this.filtro.dataInicio = this.formatDate(this.dataInicio)
                }
                if (this.dataFim) {
                    this.filtro.dataFim = this.formatDate(this.dataFim)
                }
                this.pageable.current = 1
                let queryFiltro = `ativacoes?page=${this.pageable.current}${this.$util.formatQueryUrl(this.filtro)}&orderBy=id,DESC`
                this.$router.push(queryFiltro).catch(() => { })
            }
        },

        formatDate(date) {
            const data = new Date(date)
            var dia = data.getDate().toString().padStart(2, '0'),
                mes = (data.getMonth() + 1).toString().padStart(2, '0'), //+1 pois no getMonth Janeiro começa com zero.
                ano = data.getFullYear();
            return `${ano}-${mes}-${dia}`
        },

        maskDate(date) {
            const data = new Date(date)
            var dia = data.getDate().toString().padStart(2, '0'),
                mes = (data.getMonth() + 1).toString().padStart(2, '0'),
                ano = data.getFullYear(),
                h = data.getHours().toString().padStart(2, '0'),
                m = data.getMinutes().toString().padStart(2, '0'),
                s = data.getSeconds().toString().padStart(2, '0');
            return `${dia}-${mes}-${ano} às ${h}:${m}:${s}`
        },
    },
    watch: {
        $route: {
            immediate: true,
            handler(newVal, oldVal) {
                if (newVal.query) {

                    this.pageable.current = parseInt(newVal.query.page)

                    AtivacaoService.getAtivacoes(this.pageable.current - 1, this.$util.formatQueryUrl(this.$route.query)).then((result) => {
                        this.ativacoes = result.data.content;
                        this.pageable.current = result.data.number + 1;
                        this.pageable.size = result.data.totalElements;
                        this.totalPages = result.data.totalPages;
                        this.totalElements = result.data.totalElements;
                    })

                    this.filtro = {}
                    this.dataFim = null
                    this.dataInicio = null

                }
            }
        }
    },
    computed: {
        ativacoesTable() {
            return this.ativacoes.map((ativacao) => {
                return {
                    "id": ativacao.id,
                    "placa": ativacao.placa,
                    "camera": ativacao.camera,
                    "dataEntrada": ativacao.data
                }
            })
        },
    },


    beforeDestroy() {
        this.pararRequisicoes(); // Limpa o intervalo de tempo
    }

})

</script>