<template>
  <card-component title="Editar Perfil" icon="account-circle">
    <form @submit.prevent="submit">
      <b-field horizontal label="Avatar">
        <file-picker type="is-info" />
      </b-field>
      <hr>
      <b-field horizontal label="Nome" message="Obrigatório. Seu nome">
        <b-input v-model="usuario.nome" name="name" required />
      </b-field>
      <b-field horizontal label="E-mail" message="Obrigatório. Seu e-mail">
        <b-input v-model="usuario.email" name="email" type="email" required />
      </b-field>
      <hr>
      <b-field horizontal>
        <b-field grouped>
          <div class="control">
            <b-button native-type="submit" type="is-info" :loading="isLoading">
              Salvar
            </b-button>
          </div>
        </b-field>
      </b-field>
    </form>
  </card-component>
</template>

<script>
import { defineComponent } from 'vue'
import FilePicker from '@/components/FilePicker.vue'
import CardComponent from '@/components/CardComponent.vue'
import storage from '@/mixins/storage'

export default defineComponent({
  name: 'ProfileUpdateForm',
  components: {
    CardComponent,
    FilePicker
  },
  data() {
    return {
      isLoading: false,
      usuario: {
        nome: null,
        email: null
      }
    }
  },
  mounted() {
    this.usuario.nome = storage.methods.getNome()
    this.usuario.email = storage.methods.getEmail()
  },
  computed: {
    userName: {
      get: function () {
        return this.$store.state.userName
      },
      set: function (name) {
        this.$store.commit('user', { name })
      }
    },
    userEmail: {
      get: function () {
        return this.$store.state.userEmail
      },
      set: function (email) {
        this.$store.commit('user', { email })
      }
    }
  },
  methods: {
    submit() {
      this.isLoading = true

      setTimeout(() => {
        this.isLoading = false

        this.$buefy.snackbar.open({
          message: 'Demo only',
          queue: false
        })
      }, 750)
    }
  }
})
</script>
