<template>
  <nav v-show="isNavBarVisible" id="navbar-main" class="navbar is-fixed-top">
    <div class="navbar-brand">
      <a class="navbar-item is-hidden-desktop" @click.prevent="asideToggleMobile">
        <b-icon :icon="asideMobileIcon" />
      </a>
      <a class="navbar-item is-hidden-touch is-hidden-widescreen is-desktop-icon-only"
        @click.prevent="asideDesktopOnlyToggle">
        <b-icon icon="menu" />
      </a>
    </div>
    <div class="navbar-brand is-right">
      <a class="navbar-item navbar-item-menu-toggle is-hidden-desktop" @click.prevent="menuToggle">
        <b-icon :icon="menuToggleIcon" custom-size="default" />
      </a>
    </div>
    <div class="navbar-menu fadeIn animated faster" :class="{ 'is-active': isMenuActive }">
      <div class="navbar-end">
        <nav-bar-menu class="has-divider has-user-avatar">
          <div class="is-user-name">
            <span>{{ usuario.nome }}</span>
          </div>

          <div slot="dropdown" class="navbar-dropdown">
            <router-link to="/perfil" class="navbar-item" exact-active-class="is-active">
              <b-icon icon="account" custom-size="default" />
              <span>Meu Perfil</span>
            </router-link>
            <!--<a class="navbar-item">
              <b-icon icon="settings" custom-size="default" />
              <span>Configurações</span>
            </a>
            <a class="navbar-item">
              <b-icon icon="email" custom-size="default" />
              <span>Mensagens</span>
            </a>-->
            <hr class="navbar-divider">
            <a class="navbar-item" exact-active-class="is-active" @click="logout()">
              <b-icon icon="logout" custom-size="default" />
              <span>Sair</span>
            </a>
          </div>
        </nav-bar-menu>
        <a class="navbar-item is-desktop-icon-only" title="Log out" @click="logout()">
          <b-icon icon="logout" custom-size="default" />
          <span>Sair</span>
        </a>
      </div>
    </div>
  </nav>
</template>

<script>
import { defineComponent } from 'vue'
import { mapState } from 'vuex'
import NavBarMenu from '@/components/NavBarMenu.vue'
import UserAvatar from '@/components/UserAvatar.vue'
import storage from '@/mixins/storage'

export default defineComponent({
  name: 'NavBar',
  components: {
    UserAvatar,
    NavBarMenu
  },
  data() {
    return {
      isMenuActive: false,
      usuario: {
        id: null,
        nome: null
      }
    }
  },
  computed: {
    asideMobileIcon() {
      return this.isAsideMobileExpanded ? 'backburger' : 'forwardburger'
    },
    menuToggleIcon() {
      return this.isMenuActive ? 'close' : 'dots-vertical'
    },
    ...mapState([
      'isAsideMobileExpanded',
      'isNavBarVisible',
      'userName'
    ])
  },
  mounted() {
    this.usuario.id = storage.methods.getId()
    this.usuario.nome = storage.methods.getNome()
    this.$router.afterEach(() => {
      this.isMenuActive = false
    })
  },
  methods: {
    asideToggleMobile() {
      this.$store.commit('asideMobileStateToggle')
    },
    asideDesktopOnlyToggle() {
      this.$store.dispatch('asideDesktopOnlyToggle')
    },
    menuToggle() {
      this.isMenuActive = !this.isMenuActive
    },
    logout() {
      storage.methods.clearToken()
      storage.methods.clearUser()
      storage.methods.clearEmail()
      storage.methods.clearLogin()
      storage.methods.clearNome()
      storage.methods.clearEmpresa()
      storage.methods.clearCpf()
      storage.methods.clearId()
      this.$router.push('/login')
    },

  }
})
</script>
