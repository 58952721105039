import Vue from 'vue';
import util from './Util'

export default {

  getPessoas(page, filtro) {
    if(page == undefined){
      page = 0
    }

    let requisicao = `pessoas?page=${page}`
    if(filtro){
      requisicao += `${filtro}`
    }
    return Vue.prototype.$http.get(requisicao);
  },
  
  getPessoa(id){
    return Vue.prototype.$http.get(`pessoas/${id}`);
  },

  postPessoa(pessoa){
    
    pessoa.validadeCnh = util.formatarData(pessoa.validadeCnh)
    pessoa.dataInicioAcesso = util.formatarData(pessoa.dataInicioAcesso)
    pessoa.dataFimAcesso = util.formatarData(pessoa.dataFimAcesso)
    pessoa.dataDesligamento = util.formatarData(pessoa.dataDesligamento)
    
    const form = util.createFormData(pessoa);

    const configHeader = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };
    return Vue.prototype.$http.post('/pessoas', form, configHeader)
  },
  
  putPessoa(id, pessoa){

    pessoa.validadeCnh = util.formatarData(pessoa.validadeCnh)
    pessoa.dataInicioAcesso = util.formatarData(pessoa.dataInicioAcesso)
    pessoa.dataFimAcesso = util.formatarData(pessoa.dataFimAcesso)
    pessoa.dataDesligamento = util.formatarData(pessoa.dataDesligamento)

    const form = util.createFormData(pessoa);

    const configHeader = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };

    return Vue.prototype.$http.put(`pessoas/${id}`, form, configHeader);
  },
  
  putPessoaStatus(id, status){
    var request = new Object();
    request.status = status
    return Vue.prototype.$http.put(`pessoas/${id}/status`, request);
  },

  deletePessoa(id){
    return Vue.prototype.$http.delete(`pessoas/${id}`);
  },

  desligarPessoa(id, data){
    var request = new Object();
    request.data = data
    return Vue.prototype.$http.put(`pessoas/${id}/desligar`, request);
  }
};
