<template>
    <div>
        <title-bar :title-stack="titleStack" />
        <hero-bar>
            Formulário
            <router-link slot="right" to="/" class="button">
                Dashboard
            </router-link>
        </hero-bar>
        <section class="section is-main-section">
            <form @submit.prevent="formAction" class="box">
                <card-component :title="title" icon="ballot">
                    <b-field label="Dados da Solicitação" horizontal>
                        <b-field label="Solicitante*" label-position="on-border">
                            <b-input v-model="form.autorizacao.usuarioSolicitacao" icon="account-circle-outline"
                                placeholder="Solicitante" name="solicitante" required disabled></b-input>
                        </b-field>

                        <b-field label="Data Solicitação*" label-position="on-border">
                            <b-datepicker v-model="form.autorizacao.dataSolicitacao"
                                placeholder="Selecione data e hora de início..." icon="calendar-today" trap-focus disabled>
                            </b-datepicker>
                        </b-field>

                        <b-field label="Placa do Veículo*" label-position="on-border">
                            <b-input v-model="form.autorizacao.placa" icon="card-bulleted-outline"
                                placeholder="Placa do Veículo" name="placa" required :disabled="readonly"></b-input>
                        </b-field>
                    </b-field>

                    <b-field label="Dados da Autorização" horizontal>
                        <b-field label="Destino*" label-position="on-border">
                            <b-select v-model="form.autorizacao.terminalId" placeholder="Selecione o Destino*" required
                                expanded :disabled="readonly">
                                <option v-for="item in getDestinos" :key="item.id" :value="item.id">
                                    {{ item.razaoSocial }}
                                </option>
                            </b-select>
                        </b-field>

                        <b-field label="Tipo de Autorização*" label-position="on-border">
                            <b-select v-model="form.autorizacao.tipo" placeholder="Selecione o Tipo de Autorização*"
                                required expanded disabled>
                                <option v-for="tipo in tipoAutorizacao" :key="tipo.value" :value="tipo.value">
                                    {{ tipo.label }}
                                </option>
                            </b-select>
                        </b-field>
                    </b-field>
                    <b-field horizontal>
                        <b-field label="Data de Início*" label-position="on-border"
                            message="Necessário informar data e hora.">
                            <b-datetimepicker v-model="form.autorizacao.inicio" placeholder="Data Inicio Acesso"
                                icon="calendar-today" trap-focus :disabled="readonly">
                            </b-datetimepicker>
                        </b-field>

                        <b-field label="Data de Fim*" label-position="on-border">
                            <b-datepicker v-model="form.autorizacao.fim" placeholder="Data Fim Acesso" icon="calendar-today"
                                trap-focus disabled>
                            </b-datepicker>
                        </b-field>
                    </b-field>
                    <hr>
                    <b-field horizontal v-if="!readonly">
                        <b-field grouped>
                            <div class="control">
                                <b-button native-type="submit" type="is-info" :loading="isLoading">
                                    Salvar
                                </b-button>
                            </div>
                        </b-field>
                    </b-field>
                </card-component>
            </form>
        </section>
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import HeroBar from '@/components/HeroBar.vue';
import TitleBar from '@/components/TitleBar.vue';
import CardComponent from '@/components/CardComponent.vue';
import storage from '@/mixins/storage';
import AutorizacoesService from '@/services/AutorizacoesService.js';

export default defineComponent({
    name: 'AdicionarAutorizacao',
    components: {
        HeroBar,
        TitleBar,
        CardComponent,
    },
    data() {
        return {
            titleStack: ['SECAPI', 'Autorização', this.$route.params.id ? 'Visualizar Autorização' : 'Adicionar Autorização'],
            title: this.$route.params.id ? 'Visualizar Autorização' : 'Adicionar Autorização',
            isLoading: false,
            form: {
                autorizacao: {
                    id: null,
                    usuarioSolicitacao: null
                }
            },
            tipoAutorizacao: [
                { label: 'Grão', value: 'GRAO' },
                { label: 'Tanque', value: 'TANQUE' }
            ],
            destinos: [],
            readonly: false,
        }
    },
    mounted() {
        if (this.$route.params.id) {
            this.readonly = true
            this.retrive(this.$route.params.id);
        } else {
            this.form.autorizacao.usuarioSolicitacao = storage.methods.getNome()
            this.form.autorizacao.dataSolicitacao = new Date()
            this.form.autorizacao.tipo = this.$route.query.tipo
        }

        this.$http.get(`/empresas?page=0&tipo=TERMINAL`).then((response) => {
            this.destinos = response.data.content;

        })
    },
    computed: {
        getDestinos() {
            return this.destinos.map((destino) => {
                return {
                    "razaoSocial": destino.razaoSocial,
                    "id": destino.id
                }
            })
        }
    },
    methods: {
        formAction() {
            if (!this.form.autorizacao.id) {
                this.isLoading = true
                AutorizacoesService.postAutorizacao(this.form.autorizacao)
                    .then((response) => {
                        setTimeout(() => {
                            this.$buefy.toast.open({
                                message: 'Solicitação Concluída!',
                                position: 'is-bottom',
                                type: 'is-success'
                            })
                        })
                        this.$router.push(`/autorizacoes?page=1&tipo=${this.$route.query.tipo}&orderBy=id,DESC`)
                    })
                    .catch((error) => {
                        if (error.response) {
                            this.$buefy.toast.open({
                                message: error.response.data.message,
                                position: 'is-bottom',
                                type: 'is-danger'
                            })
                        }
                    })
                    .finally(() => {
                        setTimeout(() => (this.isLoading = false), 2000)
                        this.form.autorizacao.inicio = null
                    })
            }
        },
        retrive(id) {
            AutorizacoesService.getAutorizacaoById(id).then((result) => {
                this.form.autorizacao = {
                    ...result.data,
                    usuarioSolicitacao: result.data.usuarioSolicitacao.nome,
                    terminalId: result.data.terminal.id,
                    inicio: this.$util.converterDataBanco(result.data.inicio),
                    fim: this.$util.converterDataBanco(result.data.fim),
                    dataSolicitacao: this.$util.converterDataBanco(result.data.dataSolicitacao),
                }
            })
        }
    }
})
</script>