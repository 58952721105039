<template>
    <div>
        <section>
            <title-bar :title-stack="titleStack" />
            <br><br>
            <card-component>
                <form @submit.prevent="buscar">
                    <b-field horizontal style="margin-left: auto; margin-right: auto; width: 700px;">
                        <b-field>
                            <b-input v-model="form.placa" icon="magnify" placeholder="Consultar Placa" name="placa" />
                        </b-field>
                        <b-field grouped>
                            <div class="control">
                                <b-tooltip label="Buscar" type="is-success">
                                    <b-button native-type="submit" type="is-info" icon-left="magnify"></b-button>
                                </b-tooltip>
                            </div>
                        </b-field>
                    </b-field>
                </form>
                <br>
                <mensage-consulta-placa />
            </card-component>
            <br>
        </section>
    </div>
</template>
<script>

import { defineComponent } from 'vue'
import TitleBar from '@/components/TitleBar.vue'
import CardComponent from '@/components/CardComponent.vue'
import MensageConsultaPlaca from '@/components/MensageConsultaPlaca.vue'
import NotificationBar from '@/components/NotificationBar.vue'

export default defineComponent({
    name: "ConsultarPlaca",
    components: {
        TitleBar,
        CardComponent,
        NotificationBar,
        MensageConsultaPlaca,
    },
    data() {
        return {
            titleStack: ['SECAPI', 'Veiculos', 'Consultar Placa'],
            form: {},
        }
    },
    mounted() {
    },
    methods: {
        buscar() {
            if (this.form.placa) {
                this.$router.push({
                    path: '/ConsultarPlaca',
                    query: this.form
                });
                location.reload();
            } else {
                this.$buefy.toast.open({
                    message: `Digite uma Placa!`,
                    type: 'is-warning'
                })
            }
        }
    },
    computed: {

    },
})
</script>