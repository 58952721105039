<template>
  <b-field class="file">
    <b-upload v-model="file" :accept="accept" @input="upload" :required="required">
      <a class="button" :class="type">
        <b-icon icon="upload" custom-size="default" />
        <span>{{ buttonLabel }}</span>
      </a>
    </b-upload>
    <span v-if="file" class="file-name">{{ file.name }}</span>
    <span v-if="!file" class="file-name">Nenhum arquivo selecionado.</span>
  </b-field>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'FilePicker',
  props: {
    accept: {
      type: String,
      default: null
    },
    type: {
      type: String,
      default: 'is-dark'
    },
    required: {
      type: Boolean,
      default: false
    },
  },
  emits: ['input'],
  data() {
    return {
      file: null
    }
  },
  computed: {
    buttonLabel() {
      return this.file ? 'Escolha outro arquivo' : 'Escolha um arquivo'
    }
  },
  methods: {
    upload(value) {
      this.$emit('input', value)
    }
  }
})
</script>
