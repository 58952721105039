import Vue from 'vue';

export default {

  getVeiculos(page, filtro) {
    if(page == undefined){
      page = 0
    }

    var requisicao = `veiculos?page=${page}`
    if(filtro){
      requisicao += `${filtro}`
    }
    
    return Vue.prototype.$http.get(requisicao);
  },

  getPlacaVeiculo(filtro){
    return Vue.prototype.$http.get(`veiculos?${filtro}`);
  },

  getVeiculo(id){
    return Vue.prototype.$http.get(`veiculos/${id}`);
  },

  postVeiculo(veiculo){
    return Vue.prototype.$http.post('/veiculos', veiculo)
  },
  
  putVeiculo(id, veiculo){
    return Vue.prototype.$http.put(`veiculos/${id}`, veiculo);
  },
  
  putVeiculoStatus(id, status){
    var request = new Object();
    request.status = status
    return Vue.prototype.$http.put(`veiculos/${id}/status`, request);
  },

  deleteVeiculo(id){
    return Vue.prototype.$http.delete(`veiculos/${id}`);
  },
  retornaPlaca(placa){
    return Vue.prototype.$http.get(`veiculos/placa/${placa}`)
  },
  desligar(id, data){
    var request = new Object();
    request.data = data
    return Vue.prototype.$http.put(`veiculos/${id}/desligar`, request);
  },
};
