<template>
    <div>
        <title-bar :title-stack="titleStack" />
        <hero-bar>
            Formulário
            <router-link slot="right" to="/" class="button">
                Dashboard
            </router-link>
        </hero-bar>

        <section class="section is-main-section">
            <card-component>
                <b-tabs type="is-boxed" v-model="activeTab">
                    <b-tab-item label="Criar Perfil" icon="account-cog-outline">
                        <br>
                        <form @submit.prevent="formAction">
                            <b-field horizontal label="Dados" expanded>
                                <b-field label="Role*" label-position="on-border">
                                    <b-input name="role" v-model="form.role.role" placeholder="Digite a role"
                                        icon="card-bulleted-outline" required></b-input>
                                </b-field>

                                <b-field label="Descrição*" label-position="on-border">
                                    <b-input name="descricao" v-model="form.role.descricao" placeholder="Digite a descrição"
                                        icon="card-bulleted-outline" required></b-input>
                                </b-field>

                                <b-field label="Escopo*" label-position="on-border">
                                    <b-input name="escopo" v-model="form.role.escopo" placeholder="Digite o escopo"
                                        icon="card-bulleted-outline" required></b-input>
                                </b-field>
                            </b-field>
                            <br>
                            <b-field horizontal>
                                <b-field>
                                    <div class="mr-5 mb-5">
                                        <b-button native-type="submit" type="is-info" :loading="isLoading">
                                            Salvar
                                        </b-button>
                                    </div>

                                    <div class="mr-5" v-show="$route.params.id">
                                        <b-button native-type="button" type="is-dark" outlined
                                            @click="abrirDialogDuplicarRole">
                                            Duplicar Role
                                        </b-button>
                                    </div>
                                </b-field>
                            </b-field>
                        </form>
                    </b-tab-item>

                    <b-tab-item label="Recursos" icon="apps" :disabled="!form.role.id">
                        <br>
                        <div class="info-role columns box">
                            <div class="column">
                                <h1 class="title is-5" style="color: white;">{{ form.role.role }}</h1>
                                <h2 class="subtitle is-6" style="color: white;">{{ form.role.descricao }}</h2>
                            </div>
                            <div class="column" style="display: flex; flex;justify-content: flex-end;">
                                <b-button type="is-white" @click="abrirModalAdicionarRecursos"> Adicionar Recurso</b-button>

                                <ModalAdicionarRecursos :is-active="isModalActive" @confirm="salvouRecurso"
                                    @cancel="abrirModalAdicionarRecursos"></ModalAdicionarRecursos>
                            </div>
                        </div>

                        <br>

                        <div>
                            <ListRecursos></ListRecursos>
                        </div>

                    </b-tab-item>
                </b-tabs>
            </card-component>
        </section>
    </div>
</template>

<style>
.info-role {
    padding: 1rem;
    border-radius: 0.5rem;
    background-color: hsl(229, 53%, 53%);
}
</style>

<script>
import { defineComponent } from 'vue'
import HeroBar from '@/components/HeroBar.vue';
import CardComponent from '@/components/CardComponent.vue';
import TitleBar from '@/components/TitleBar.vue';
import RoleService from '@/services/RoleService';
import ListRecursos from '@/components/ListRecursos.vue';
import ModalAdicionarRecursos from '@/components/ModalAdicionarRecursos.vue';

export default defineComponent({
    name: 'FormPerfil',
    components: {
        HeroBar,
        CardComponent,
        TitleBar,
        ListRecursos,
        ModalAdicionarRecursos
    },
    data() {
        return {
            titleStack: ['SECAPI', 'Configurações', 'Criar Perfil'],
            form: {
                role: {
                    id: null
                }
            },
            activeTab: 0,
            isLoading: false,
            isModalActive: false,
        }
    },
    methods: {

        formAction() {
            if (!this.form.role.id) {
                this.adicionarRole();
            }else{
                this.alterarRole();
            }
        },

        adicionarRole() {
            this.isLoading = true
            RoleService.postRole(this.form.role)
                .then((result) => {
                    this.form.role = result.data;
                    setTimeout(() => {
                        this.$buefy.toast.open({
                            message: 'Role Cadastrada!',
                            position: 'is-bottom',
                            type: 'is-success'
                        })
                    })
                    if (this.form.role.id) {
                        this.$router.push(`formPerfil/${this.form.role.id}`)
                        this.activeTab = 1;
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        this.$buefy.toast.open({
                            message: error.response.data,
                            position: 'is-bottom',
                            type: 'is-danger'
                        })
                    }
                    console.error(error.response)
                })
                .finally(() => {
                    setTimeout(() => (this.isLoading = false), 2000);
                })
        },

        alterarRole() {
            this.isLoading = false
            RoleService.putRole(this.form.role.id, this.form.role)
                .then((result) => {
                    this.form.role = result.data;
                    setTimeout(() => {
                        this.$buefy.toast.open({
                            message: 'Role Alterada!',
                            position: 'is-bottom',
                            type: 'is-success'
                        })
                    })
                    if (this.form.role.id) {
                        location.reload();
                        this.activeTab = 1;
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        this.$buefy.toast.open({
                            message: `Erro ao alterar Role: ${error.response.data}`,
                            position: 'is-bottom',
                            type: 'is-danger'
                        })
                    }
                    console.error(error.response)
                })
                .finally(() => {
                    setTimeout(() => (this.isLoading = false), 2000);
                })
        },

        retrive(idRole) {
            RoleService.getRole(idRole).then((result) => {
                this.form.role = result.data;
            })
        },

        abrirModalAdicionarRecursos() {
            this.isModalActive ? this.isModalActive = false : this.isModalActive = true
        },

        salvouRecurso() {
            this.isModalActive = false
            location.reload()
        },

        abrirDialogDuplicarRole() {
            this.$buefy.dialog.confirm({
                message: 'Deseja realmetente <b>duplicar</b> essa Role ?',
                confirmText: 'Sim',
                cancelText: 'Cancelar',
                title: 'Confirmar Duplicação de Role',
                hasIcon: true,
                icon: 'alert',
                onConfirm: () => this.duplicarRole()
            })
        },

        duplicarRole() {
            RoleService.duplicarRole(this.form.role.id)
                .then((result) => {
                    this.$buefy.toast.open({
                        message: 'Role Duplicada com Sucesso!',
                        type: 'is-success',
                        position: 'is-bottom'
                    })
                    this.$router.push('/gerenciarPerfis?page=1&orderBy=id,DESC')
                })
                .catch((error) => {
                    this.$buefy.toast.open({
                        message: 'Ocorreu um error ao Duplicar Role.',
                        type: 'is-danger',
                        position: 'is-bottom'
                    })
                    console.error(error);
                })
        }
    },
    mounted() {
        if (this.$route.params.id) {
            this.retrive(this.$route.params.id)
            this.activeTab = 1
        }
    }

})
</script>