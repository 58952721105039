import Vue from 'vue';

export default {

    getRecursos(page, filtro) {
        if (page == undefined) {
            page = 0
        }

        let requisicao = `recursos?page=${page}`
        if (filtro) {
            requisicao += `${filtro}`
        }

        return Vue.prototype.$http.get(requisicao);
    },

    postRecurso(recurso){
        return Vue.prototype.$http.post('/recursos', recurso);
    }
}