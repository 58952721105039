<template>
  <footer
    v-show="isFooterBarVisible"
    class="footer"
  >
    <div class="container-fluid">
      <div class="level">
        <div class="level-left">
          <div class="level-item">
            <div class="footer-copyright">
              <b>Copyright &copy; 2023 EMAP - SECAPI</b> Todos os direitos reservados. <b>Versão</b> {{ versao }} 
            </div>
          </div>
        </div>
        <div class="level-right">
          <div class="level-item">
            <div class="logo">
              <b-image :src="require('@/assets/logo-emap.jpg')"></b-image>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import { defineComponent } from 'vue'
import { mapState } from 'vuex'
import packageJson from '../../package.json';

export default defineComponent({
  name: 'FooterBar',
  data () {
    return {
      year: new Date(),
      versao: packageJson.version
    }
  },
  computed: {
    ...mapState([
      'isFooterBarVisible'
    ]),

    dataAtual(){
      return `${this.year.getDate()} / ${this.year.getMonth()+1} / ${this.year.getFullYear()}`
    }
  }
})
</script>
