import Vue from 'vue';

export default {

  getAtivacoes(page, filtro) {
    if(page == undefined){
      page = 0
    }

    var requisicao = `ativacoes?page=${page}`
    if(filtro){
      requisicao += `${filtro}`
    }
    
    return Vue.prototype.$http.get(requisicao);
  },

  getMonitoramento(camera){
    return Vue.prototype.$http.get(`ativacoes/monitoramento/${camera}`)
  },

  ativarCancela(maquina){
    return Vue.prototype.$http.get(`ativacoes/ativarCancela/${maquina}`)
  }
};
