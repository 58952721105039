<template>
    <div style="width: 400px; margin-left: auto; margin-right: auto;">
        <b-field v-for="veiculo in veiculosFormat" :key="veiculo.id">
            <b-message
                :type="(veiculo.status == 'CADASTRADO')? 'is-success': 'is-danger'" 
                aria-close-label="Close message"
                has-icon
            >
                <b>Placa: </b>{{ veiculo.placa }},
                <br> 
                <b>Estado: </b>{{ veiculo.status }},
                <br>
                <b>Area de Acesso: </b><span v-if="veiculo.area == 'PRIMARIA'">ÁREA PRIMÁRIA</span ><span v-else>ÁREA SECUNDÁRIA</span >
                <br>
                <b><span v-if="veiculo.status != 'CADASTRADO'">NÃO LIBERADO!</span ><span v-else>LIBERADO!</span ></b>
            </b-message>
        </b-field>
        <b-field v-show="veiculosFormat.length == 0 && PlacaVeiculo.length > 0">
            <b-message 
                type="is-danger" 
                aria-close-label="Close message"
                has-icon>
                <b>Placa:</b> {{ PlacaVeiculo }} <br>
                <b>NÃO ENCONTRADO!</b>  
            </b-message>            
        </b-field>        
    </div>
</template>
<script>    
import { defineComponent } from 'vue'
import VeiculoService from '@/services/VeiculoService.js'

export default defineComponent({
name: "MensagemPlaca",
data(){
    return {
        veiculos: [],
        PlacaVeiculo: ''
    }
},
mounted() {
    this.resultPlaca()
    this.PlacaVeiculo =  (this.$route.query.placa !== undefined)? this.$route.query.placa:'' 
},
methods: {
        
    resultPlaca(){
        VeiculoService.getPlacaVeiculo(this.placa).then((response) =>{
            this.veiculos = response.data.content;
        })
    }, 

    formatQueryUrl(params) {
        let query = ""
        Object.keys(params).forEach(key => {

            if (key && key != "page") {
            query += `&${key}=${params[key]}`
            }

        });
        return query
    }
},
computed: {          
    placa() {
        return this.formatQueryUrl(this.$route.query);
    }, 

    veiculosFormat() {
        return this.veiculos.filter((veiculo) => {
            if(veiculo.placa ==  this.PlacaVeiculo){
                return {
                    "id": veiculo.id,
                    "placa": veiculo.placa,
                    "empresa": veiculo.empresa.razaoSocial,
                    "marca": veiculo.marca,
                    "modelo": veiculo.modelo,
                    "ano": veiculo.anoFabricacao,
                    "status": veiculo.status,
                    "usuario": veiculo.usuario,
                    "area": veiculo.area
                } 
            }
        })
    },

}

})
</script>