<template>
  <div>
    <div>
      <p style="text-align:center; font-size:2rem;"><b>SECAPI+</b></p>
      <p style="text-align:center; font-size: 1rem;">SISTEMA ELETRÔNICO DE CONTROLE DE ACESSO DO PORTO DO ITAQUI</p>
    </div>
    <card-component class="sombra" title="Login" icon="lock" style="margin-top: 0.6rem;">
      <form method="POST" @submit.prevent="submit">
        <b-field label="Usuário">
          <b-input v-model="form.login" name="login" required />
        </b-field>

        <b-field label="Senha">
          <b-input v-model="form.password" type="password" name="password" required password-reveal />
        </b-field>

        <b-field class="mt-3">
          <div class="buttons">
            <b-button native-type="submit" type="is-primary" :loading="isLoading" expanded>
              Entrar
            </b-button>
          </div>
        </b-field>

        <b-field position="is-centered">
          <div class="control">
            <router-link to="/recuperarSenha">
              Esqueci minha senha
            </router-link>
          </div>
        </b-field>
      </form>
      <hr>
      <div style="text-align: center;">
        <img src="@/assets/logo-emap.jpg" alt="porto do itaqui" style="width: 150px;">
      </div>
    </card-component>
  </div>
</template>


<style>
.card-content {
  padding-top: 1rem;
}

.sombra {
  background-color: hsl(0, 0%, 100%);
  box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1), 0 0px 0 1px rgba(10, 10, 10, 0.02);
  color: hsl(0, 0%, 29%);
  display: block;
}
</style>

<script>
import { defineComponent } from 'vue'
import CardComponent from '@/components/CardComponent.vue'
import TitleBar from '@/components/TitleBar.vue'
import HeroBar from '@/components/HeroBar.vue'
import storage from '@/mixins/storage'
import axios from 'axios'

export default defineComponent({
  name: 'LoginView',
  components: {
    CardComponent,
    TitleBar,
    HeroBar
  },
  data() {
    return {
      isLoading: false,
      form: {
        login: "",
        password: "",
      }
    }
  },
  mounted() {
  },
  methods: {
    async submit () {
      this.isLoading = true
      
      axios.post(process.env.VUE_APP_API_URL + "/auth", {
          password: this.form.password,
          username: this.form.login
        })
        .then((response) => {
          console.log(response)
          const token = response.data.token,
            user = response.data.role.descricao,
            role = response.data.role.role,
            login = response.data.login,
            email = response.data.email,
            nome = response.data.nome,
            empresa = response.data.empresa.razaoSocial,
            cpf = response.data.cpf,
            id = response.data.id
          this.$http.defaults.headers.common[
            "Authorization"
          ] = token;
          storage.methods.setId(id)
          storage.methods.setToken(token)
          storage.methods.setUser(user)
          storage.methods.setRole(role)
          storage.methods.setLogin(login)
          storage.methods.setEmail(email)
          storage.methods.setNome(nome)
          storage.methods.setEmpresa(empresa)
          storage.methods.setCpf(cpf)
          window.location.href = '/'
          //this.$router.push('/')
        })
        .catch( error => {
          this.$buefy.toast.open({
            message: error.response.data.message,
            position: 'is-bottom',
            type: 'is-danger'
          })

          /*setTimeout(() => {
            location.reload()
          }, 2000);*/

        })
        .finally(() => {
          setTimeout(() => {
            this.isLoading = false
          }, 2000);

        });

    },
  }
})
</script>
