<template>
  <card-component title="Alterar Senha" icon="lock">
    <form @submit.prevent="submit">
      <b-field horizontal label="Senha Atual" message="Obrigatório. Sua senha atual">
        <b-input v-model="form.senhaAtual" name="password_current" type="password" required
          autcomplete="current-password" password-reveal/>
      </b-field>
      <hr>
      <b-field horizontal label="Nova Senha" message="Obrigatório. Nova senha">
        <b-input v-model="form.novaSenha" name="password" type="password" required autocomplete="new-password" password-reveal/>
      </b-field>
      <hr>
      <b-field horizontal label="Confirmar Nova Senha" message="Obrigatório. Repita a nova senha">
        <b-input v-model="confirmarSenha" name="password_confirmation" type="password" required
          autocomplete="new-password" password-reveal/>
      </b-field>

      <div>
        <template v-if="valid_password">
          <b-field horizontal>
            <b-tag v-if="isTagActive" type="is-success" icon="check" aria-close-label="Close tag"
              @close="isTagActive = false">
              Senhas Verificadas
            </b-tag>
          </b-field>
        </template>

        <template v-else>
          <b-field horizontal>
            <verificar-senhas :password="form.novaSenha" :confirm-password="confirmarSenha"
              @senhaValida="handleSenhaValida"></verificar-senhas>
          </b-field>
        </template>
      </div>

      <hr>
      <b-field horizontal>
        <div class="control">
          <b-button native-type="submit" type="is-info" :loading="isLoading" :disabled="!valid_password">
            Alterar Senha
          </b-button>
        </div>
      </b-field>
    </form>
  </card-component>
</template>

<script>
import { defineComponent } from 'vue'
import CardComponent from '@/components/CardComponent.vue'
import usuarioService from '@/services/UsuarioService'
import VerificarSenhas from '@/components/VerificarSenhas.vue'


export default defineComponent({
  name: 'PasswordUpdateForm',
  components: {
    CardComponent,
    VerificarSenhas
  },
  data() {
    return {
      isLoading: false,
      form: {
        senhaAtual: null,
        novaSenha: null
      },
      confirmarSenha: null,
      valid_password: false,
      isTagActive: true
    }
  },
  methods: {
    submit() {

      if (this.valid_password) {
        this.isLoading = true
        usuarioService.alterarSenha(this.form)
          .then(response => {
            setTimeout(() => {
              this.isLoading = false
              this.$buefy.snackbar.open(
                {
                  message: 'Senha Alterada com Sucesso!',
                  queue: false
                }
              )
            }, 750)
            this.form = {};
            this.confirmarSenha = null
            //this.$router.push("/login");
          })
          .catch(error => {
            if (error.response) {
              this.$buefy.toast.open({
                message: error.response.data.message,
                position: 'is-bottom',
                type: 'is-danger'
              })
              this.isLoading = false
              console.log(error.response)
            }
          });
      }
    },

    handleSenhaValida(valida) {
      this.valid_password = valida;
    },
  },
  watch: {
    'form.novaSenha': {
      handler() {
        this.valid_password = false; // Redefina para falso ao alterar a senha
      },
    },
    'confirmarSenha': {
      handler() {
        this.valid_password = false; // Redefina para falso ao alterar a senha
      },
    },
  },
})
</script>
