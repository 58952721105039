/* Styles & axios */
import '@/scss/main.scss'
import axios from 'axios'

/* Core */
import Vue from 'vue'
import Buefy from 'buefy'

/* Router, storage & Store */
import router from './router'
import store from './store'
import storage from './mixins/storage'
import Util from './services/Util'

/* Vue. Main component */
import App from './App.vue'

/* VueMask */
import VueMask from 'v-mask'

/* Fetch sample data */
//store.dispatch('fetch', 'clients')

/* Default title tag */
const defaultDocumentTitle = 'SECAPI'
const urlAPI = process.env.VUE_APP_API_URL

/* Collapse mobile aside menu on route change & set document title from route meta */
router.afterEach(to => {
  store.commit('asideMobileStateToggle', false)
  store.dispatch('asideDesktopOnlyToggle', false)

  if (to.meta && to.meta.title) {
    document.title = `${to.meta.title} — ${defaultDocumentTitle}`
  } else {
    document.title = defaultDocumentTitle
  }
})


const axiosConfig = {
  baseURL: urlAPI,
  timeout: 30000,
};

Vue.prototype.$http = axios.create(axiosConfig)
Vue.prototype.$http.defaults.headers.common['Access-Control-Allow'] = "*"
let token = storage.methods.getToken()

if (token) {
  Vue.prototype.$http.defaults.headers.common['Authorization'] = token
}

const errorInterceptor = error => {

  // check if it's a server error
  if (!error.response) {
    //notify.warn('Network/Server error');
    console.error('**Network/Server error');
    console.log(error.response);
    return Promise.reject(error);
  }

  // all the other error responses
  switch (error.response.status) {

    case 401: // authentication error, logout the user
      console.log(error.response.status, error.message);
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      localStorage.removeItem('login');
      localStorage.removeItem('nome');
      localStorage.removeItem('email');
      localStorage.removeItem('empresa');
      localStorage.removeItem('cpf');
      localStorage.removeItem('id');



      router.push('/login').catch(() => { });

      break;

    case 403: //sem nivel de acesso
      console.log(error.response.status, error.message);
      Vue.prototype.$buefy.toast.open({
        message: `Acesso negado. Você não tem permissão para acessar este recurso!`,
        position: 'is-bottom',
        type: 'is-danger',
        duration: 4000
      })
      //window.history.back();
      break;

  }
  return Promise.reject(error);
}

Vue.prototype.$http.interceptors.response.use(undefined, errorInterceptor);

Vue.config.productionTip = false

Vue.use(Buefy)
Vue.prototype.$util = Util;

Vue.use(VueMask)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
