<template>
    <div class="field-check box">
        <ul>
            <li v-bind:class="{ is_valid: contains_eight_characters }">
                <b-icon :icon="contains_eight_characters ? 'check' : 'chevron-right'" size="is-small"></b-icon> Mínimo 8
                Caracteres
            </li>
            <li v-bind:class="{ is_valid: contains_number }">
                <b-icon :icon="contains_number ? 'check' : 'chevron-right'" size="is-small"></b-icon> Contém Número
            </li>
            <li v-bind:class="{ is_valid: contains_uppercase }">
                <b-icon :icon="contains_uppercase ? 'check' : 'chevron-right'" size="is-small"></b-icon> Contém Letras
                Maiúsculas
            </li>
            <li v-bind:class="{ is_valid: contains_special_character }">
                <b-icon :icon="contains_special_character ? 'check' : 'chevron-right'" size="is-small"></b-icon> Contém
                Caracteres Especiais
            </li>
            <li v-bind:class="{ is_valid: confirm_password }">
                <b-icon :icon="confirm_password ? 'check' : 'chevron-right'" size="is-small"></b-icon> Confirmar Senha
            </li>
        </ul>
    </div>
</template>

<style>
.field-check {
    padding: 0.5rem 0.5rem 1rem;
    background-color: rgba(241, 241, 241, 0.407);
    border-radius: 0.5rem;
}


.is_valid {
    font-weight: bold;
    color: rgba(15, 183, 113, 0.8);
}

.is_valid:before {
    width: 100%;
}
</style>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'VerificarSenha',
    props: {
        password: {
            type: String,
            default: null
        },
        confirmPassword: {
            type: String,
            default: null
        },
    },
    data() {
        return {
            contains_eight_characters: false,
            contains_number: false,
            contains_uppercase: false,
            contains_special_character: false,
            confirm_password: false,
        };
    },
    computed: {
        isValidPassword() {
            const format = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;
            return (
                this.password.length >= 8 &&
                /\d/.test(this.password) &&
                /[A-Z]/.test(this.password) &&
                format.test(this.password) &&
                this.password === this.confirmPassword
            );
        },
    },
    watch: {
        password() {
            this.updateValidation();
        },
        confirmPassword() {
            this.updateValidation();
        },
    },
    methods: {
        updateValidation() {
            this.contains_eight_characters = this.password.length >= 8;
            this.contains_number = /\d/.test(this.password);
            this.contains_uppercase = /[A-Z]/.test(this.password);
            this.contains_special_character = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(this.password);
            this.confirm_password = this.password === this.confirmPassword;
            this.$emit('senhaValida', this.isValidPassword);
        },
    },

})

</script>