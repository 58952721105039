<template>
    <b-modal :active.sync="isModalActive" has-modal-card :width="1000">
        <div class="modal-card">
            <header class="modal-card-head">
                <p class="modal-card-title">
                    <b>Adicionar Recursos</b>
                </p>
                <button type="button" class="delete" @click="cancel" />
            </header>

            <section class="modal-card-body">
                <card-component>
                    <form @submit.prevent="salvar">
                        <b-field label="Descrição*" label-position="on-border">
                            <b-input name="descricao" v-model="form.recurso.descricao" placeholder="Descrição"
                                icon="card-bulleted-outline" required>
                            </b-input>
                        </b-field>

                        <b-field label="Rota*" label-position="on-border">
                            <b-input name="descricao" v-model="form.recurso.rota" placeholder="Descrição"
                                icon="card-bulleted-outline" required>
                            </b-input>
                        </b-field>

                        <b-field label="Método*" label-position="on-border">
                            <b-select expanded placeholder="Selecione o tipo de Método" required
                                v-model="form.recurso.metodo">
                                <option v-for="(item, index) in metodos" :key="index" :value="item">
                                    {{ item }}
                                </option>
                            </b-select>
                        </b-field>

                        <b-field label="Menu*" label-position="on-border">
                            <b-select expanded placeholder="Selecione o menu..." required v-model="form.recurso.menu">
                                <option v-for="(item, index) in listMenu" :key="index" :value="item.value">
                                    {{ item.label }}
                                </option>
                            </b-select>
                        </b-field>

                        <b-field grouped>
                            <div class="control">
                                <b-button native-type="submit" type="is-info" :loading="isLoading">
                                    Salvar
                                </b-button>
                            </div>
                        </b-field>
                    </form>
                </card-component>
            </section>
            <footer class="modal-card-foot" style="justify-content: flex-end;">
            </footer>
        </div>
    </b-modal>
</template>

<script>
import { defineComponent } from 'vue'
import CardComponent from './CardComponent.vue'
import RecursosService from '@/services/RecursosService'

export default defineComponent({
    name: 'ModalAdicionarRecuros',
    components: { CardComponent },
    props: {
        isActive: Boolean,
    },
    emits: ['confirm', 'cancel'],
    data() {
        return {
            form: {
                recurso: {

                }
            },
            metodos: ['GET', 'POST', 'PUT', 'DELETE'],
            listMenu: [
                { label: 'Veículos', value: 'VEICULOS', icon: 'truck-outline' },
                { label: 'Pessoas', value: 'PESSOAS', icon: 'account-multiple' },
                { label: 'Empresas', value: 'EMPRESAS', icon: 'city-variant-outline' },
                { label: 'Autorizações', value: 'AUTORIZACOES', icon: 'lock' },
                { label: 'Configurações', value: 'CONFIGURACOES', icon: 'settings' }
            ],
            isLoading: false,
        }
    },
    computed: {
        isModalActive: {
            get: function () {
                return this.isActive
            },
            set: function (value) {
                if (!value) {
                    this.cancel()
                }
            }
        },

    },
    methods: {

        cancel() {
            this.form.recurso = {}
            this.$emit('cancel')
        },

        salvar() {
            this.isLoading = true
            RecursosService.postRecurso(this.form.recurso)
                .then((result) => {
                    setTimeout(() => {
                        this.$buefy.toast.open({
                            message: 'Recurso cadastrado!',
                            position: 'is-bottom',
                            type: 'is-success'
                        })
                    })

                    this.form.recurso = {}
                    this.$emit('confirm')
                })
                .catch((error) => {
                    if (error.response) {
                        this.$buefy.toast.open({
                            message: `Erro ao cadastrar recurso: ${error.response.data}`,
                            position: 'is-bottom',
                            type: 'is-danger'
                        })
                    }
                    console.error(error.response)
                })
                .finally(() => {
                    setTimeout(() => (this.isLoading = false), 2000);
                })
        }

    }
})
</script>