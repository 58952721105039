<template>
  <div>
    <title-bar :title-stack="titleStack" />
    <hero-bar>
      Formulário
      <router-link slot="right" to="/" class="button">
        Dashboard
      </router-link>
    </hero-bar>
    <section class="section is-main-section">
      <form @submit.prevent="formAction" autocomplete="off" class="box">
        <card-component title="Adicionar Usuário" icon="ballot">
          <b-field label="Dados do Usuário" horizontal expanded>
            <b-field style="width: 100%;" label="Selecione uma Empresa*" :label-position="labelPosition" expanded>
              <b-select icon="city-variant-outline" v-model="form.usuario.idEmpresa"
                placeholder="Selecione uma Contratante*" required expanded>
                <option v-for="empresa in getEmpresas" :key="empresa.id" :value="empresa.id">
                  {{ empresa.razaoSocial }}
                </option>
              </b-select>
            </b-field>
          </b-field>

          <b-field horizontal>
            <b-field label="Nome*" :label-position="labelPosition" style="">
              <b-input v-model="form.usuario.nome" icon="account-circle" placeholder="Nome" name="nome" required />
            </b-field>
            <b-field label="CPF*" :label-position="labelPosition">
              <b-input v-model="form.usuario.cpf" icon="card-bulleted-outline" placeholder="CPF" name="CPF" required
                v-mask="'###.###.###-##'" />
            </b-field>
            <b-field label="Selecione um perfil*" :label-position="labelPosition" expanded>
              <b-select v-model="form.usuario.idRole" placeholder="Selecione um perfil*" required expanded>
                <option v-for="role in getRoles" :key="role.id" :value="role.id">
                  {{ role.descricao }}
                </option>
              </b-select>
            </b-field>
          </b-field>


          <b-field horizontal>
            <b-field label="Email*" :label-position="labelPosition">
              <b-input v-model="form.usuario.email" icon="email-outline" placeholder="E-mail" name="email" required
                type="email" autocomplete="off" />
            </b-field>
            <b-field label="Login*" :label-position="labelPosition">
              <b-input v-model="form.usuario.login" icon="card-bulleted-outline" placeholder="Login" name="login"
                required />
            </b-field>
            <b-field label="Tipo Autenticação*" :label-position="labelPosition" expanded>
              <b-select v-model="form.usuario.tipoAutenticacao" placeholder="Selecione o tipo de autenticação*" required
                expanded>
                <option v-for="auth in tipoAuth" :key="auth.valor" :value="auth.valor">
                  {{ auth.label }}
                </option>
              </b-select>
            </b-field>

            <b-field label="Data Validade*" :label-position="labelPosition" expanded>
              <b-datepicker v-model="form.usuario.validade" placeholder="Data Validade" icon="calendar-today" trap-focus
                required>
              </b-datepicker>
            </b-field>
          </b-field>

          <b-field horizontal v-if="deveExibirCampo()">

            <b-field label="Senha*" :label-position="labelPosition">
              <b-input v-model="form.usuario.senha" icon="card-bulleted-outline" placeholder="Senha" name="senha"
                type="password" required password-reveal autocomplete="off" />
            </b-field>

            <b-field label="Confirmar Senha*" :label-position="labelPosition">
              <b-input v-model="form.usuario.confirmarSenha" icon="card-bulleted-outline" placeholder="Confirmar Senha"
                type="password" name="confSenha" required password-reveal autocomplete="off" />
            </b-field>

          </b-field>

          <div v-if="deveExibirCampo()">
            <template v-if="valid_password">
              <b-field horizontal>
                <b-tag v-if="isTagActive" type="is-success" icon="check" aria-close-label="Close tag"
                  @close="isTagActive = false">
                  Senhas Verificadas
                </b-tag>
              </b-field>
            </template>

            <template v-else>
              <b-field horizontal>
                <verificar-senhas :password="form.usuario.senha" :confirm-password="form.usuario.confirmarSenha"
                  @senhaValida="handleSenhaValida"></verificar-senhas>
              </b-field>
            </template>
          </div>

          <br>
          <b-field horizontal>
            <b-field>
              <div class="control">
                <b-button native-type="submit" type="is-info" :loading="isLoading">
                  Salvar
                </b-button>
              </div>
            </b-field>
          </b-field>
        </card-component>
      </form>
    </section>
  </div>
</template>
  
<script>
import { defineComponent } from 'vue'
import TitleBar from '@/components/TitleBar.vue'
import CardComponent from '@/components/CardComponent.vue'
import FilePicker from '@/components/FilePicker.vue'
import HeroBar from '@/components/HeroBar.vue'
import CheckboxRadioPicker from '@/components/CheckboxRadioPicker.vue'
import UsuarioService from '@/services/UsuarioService.js'
import RoleService from '@/services/RoleService.js'
import VerificarSenhas from '@/components/VerificarSenhas.vue'

export default defineComponent({
  name: 'FormUsuario',
  components: {
    CheckboxRadioPicker,
    HeroBar,
    FilePicker,
    CardComponent,
    TitleBar,
    VerificarSenhas,
  },
  data() {
    return {
      titleStack: ['SECAPI', 'Configurações', 'Adicionar Usuário'],
      tipoAuth: [
        {
          "label": "Padão",
          "valor": "PADRAO",
        },

        {
          "label": "LPAD",
          "valor": "LPAD",
        }
      ],
      form: {
        usuario: {
          id: null,
          tipoAutenticacao: "PADRAO"
        },
      },
      isLoading: false,
      empresas: [],
      roles: [],
      usuarios: [],
      pageable: {
        pageNumber: 0
      },
      size: 1000,
      labelPosition: 'on-border',
      isLoading: false,
      valid_password: false,
      isTagActive: true
    }
  },
  mounted() {
    this.$http.get(`/empresas?page=${this.pageable.pageNumber}&size=${this.size}&orderBy=razaoSocial,ASC`).then((response) => {
      this.empresas = response.data.content;

    })

    RoleService.getRoles(this.pageable.pageNumber, `&size=${this.size}`).then((response) => {
      this.roles = response.data.content;
    })

    if (this.$route.params.id) {
      this.editar(this.$route.params.id)
    }
  },
  methods: {
    formAction() {
      if (this.form.usuario.id) {
        this.alterarUsuario();
      } else {
        this.adicionarUsuario();
      }
    },

    voltar() {
      window.history.back();
    },

    editar(id) {
      UsuarioService.getUsuario(id).then((response) => {
        this.form.usuario = { ...response.data, validade: this.$util.converterData(response.data.validade), idRole: response.data.role.id, idEmpresa: response.data.empresa.id }
      })
    },

    alterarUsuario() {
      this.isLoading = true
      UsuarioService.putUsuario(this.form.usuario.id, this.form.usuario).then((response) => {
        this.usuarios.push(response.data);
        setTimeout(() => {
          this.$buefy.toast.open({
            message: 'Usuário atualizado!',
            position: 'is-bottom',
            type: 'is-success'
          })
        }),
          this.$router.push('/usuarios?page=1&orderBy=id,DESC')
      }).catch((error) => {
        if (error.response) {
          this.$buefy.toast.open({
            message: error.response.data,
            position: 'is-bottom',
            type: 'is-danger'
          })
          console.log(error.response)
        }
      }).finally(() => {
        setTimeout(() => (this.isLoading = false), 2000);
      })

    },

    adicionarUsuario() {
      if (this.validacao()) {
        this.isLoading = true
        UsuarioService.postUsuario(this.form.usuario).then((response) => {
          this.usuarios.push(response.data);
          setTimeout(() => {
            this.$buefy.toast.open({
              message: 'Usuário cadastrado!',
              position: 'is-bottom',
              type: 'is-success'
            })
          }),
            this.$router.push('/usuarios?page=1&orderBy=id,DESC')
        })
          .catch((error) => {
            if (error.response) {
              this.$buefy.toast.open({
                message: error.response.data,
                position: 'is-bottom',
                type: 'is-danger'
              })
              console.error(error.response)
            }
          }).finally(() => {
            setTimeout(() => (this.isLoading = false), 2000);
          })
      }
    },

    validacao() {

      if (this.form.usuario.tipoAutenticacao === 'LPAD') {
        return true
      }

      if (!this.valid_password) {
        return false
      }

      return true; // Validação passou
    },

    deveExibirCampo() {
      if (this.form.usuario.id) {
        return false
      } else {
        if (this.form.usuario.tipoAutenticacao === 'LPAD') {
          return false
        }
      }
      return true
    },

    handleSenhaValida(valida) {
      this.valid_password = valida;
    },
  },
  computed: {
    getEmpresas() {
      return this.empresas.map((empresa) => {
        return {
          "razaoSocial": empresa.razaoSocial,
          "id": empresa.id
        }
      })
    },

    getRoles() {
      return this.roles.map((role) => {
        return {
          "descricao": role.descricao,
          "id": role.id,
          "role": role.role
        }
      })
    },
  },

  watch: {
    'form.usuario.senha': {
      handler() {
        this.valid_password = false; // Redefina para falso ao alterar a senha
      },
    },
    'form.usuario.confirmarSenha': {
      handler() {
        this.valid_password = false; // Redefina para falso ao alterar a senha
      },
    },
  },
})
</script>