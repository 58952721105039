<template>
  <div>
    <div class="mb-2">
      <p style="text-align:center; font-size:2rem;"><b>SECAPI+</b></p>
      <p style="text-align:center; font-size:1rem;">SISTEMA ELETRÔNICO DE CONTROLE DE ACESSO DO PORTO DO ITAQUI</p>
    </div>
    <card-component title="SECAPI - Recuperação de Senha" icon="lock">
      <form @submit.prevent="submit">
        <b-field label="Nova Senha*" message="Obrigatório. Nova Senha">
          <b-input v-model="form.password" type="password" name="password" required password-reveal />
        </b-field>

        <b-field label="Confirmar Senha*" message="Obrigatório. Repita a Nova Senha">
          <b-input v-model="confirmarSenha" type="password" name="confirmarSenha" required password-reveal />
        </b-field>

        <div>
          <template v-if="valid_password">
            <b-field>
              <b-tag v-if="isTagActive" type="is-success" icon="check" aria-close-label="Close tag"
                @close="isTagActive = false">
                Senhas Verificadas
              </b-tag>
            </b-field>
          </template>

          <template v-else>
            <b-field>
              <verificar-senhas :password="form.password" :confirm-password="confirmarSenha"
                @senhaValida="handleSenhaValida"></verificar-senhas>
            </b-field>
          </template>
        </div>


        <b-field class="mt-5">
          <div class="buttons">
            <b-button native-type="submit" type="is-info" :loading="isLoading" :disabled="!valid_password" expanded>
              Redefinir Senha
            </b-button>
          </div>
        </b-field>
      </form>
      <hr>
      <div style="text-align: center;">
        <img src="@/assets/logo-emap.jpg" alt="porto do itaqui" style="width: 150px;">
      </div>
    </card-component>
  </div>
</template>
  
<script>
import { defineComponent } from 'vue'
import CardComponent from '@/components/CardComponent.vue'
import usuarioService from '@/services/UsuarioService'
import VerificarSenhas from '@/components/VerificarSenhas.vue'


export default defineComponent({
  name: 'ResetSenha',
  components: { CardComponent, VerificarSenhas },
  data() {
    return {
      isLoading: false,
      form: {
        password: null,
        token: null
      },
      confirmarSenha: null,
      valid_password: false,
      isTagActive: true
    }
  },
  mounted() {
    this.form.token = this.$route.query.token

  },
  methods: {
    validacao() {

      if (!this.form.token) {
        this.$buefy.toast.open({
          message: `Token indisponível.`,
          position: 'is-bottom',
          type: 'is-danger',
          duration: 4000
        });
        return false;
      }

      return true; // Validação passou
    },

    handleSenhaValida(valida) {
      this.valid_password = valida;
    },

    submit() {
      if (this.validacao()) {
        this.isLoading = true
        usuarioService.resetSenha(this.form)
          .then(response => {
            this.form = {};
            this.$buefy.toast.open({
              message: `Senha Recuperada!`,
              position: 'is-bottom',
              type: 'is-success',
              duration: 4000
            });
            this.isLoading = false
            this.$router.push("/login");

          })
          .catch(error => {
            if (error.response) {
              this.$buefy.toast.open({
                message: `Não foi possível alterar a senha.`,
                position: 'is-bottom',
                type: 'is-danger'
              })
              this.isLoading = false
              console.log(error.response)
            }
          });
      }
    },
  },
  watch: {
    'form.password': {
      handler() {
        this.valid_password = false; // Redefina para falso ao alterar a senha
      },
    },
    'confirmarSenha': {
      handler() {
        this.valid_password = false; // Redefina para falso ao alterar a senha
      },
    },
  },
})
</script>
  