<template>
  <div>

    <div class="mb-2">
      <p style="text-align:center; font-size:2rem;"><b>SECAPI+</b></p>
      <p style="text-align:center; font-size:1rem;">SISTEMA ELETRÔNICO DE CONTROLE DE ACESSO DO PORTO DO ITAQUI</p>
    </div>

    <card-component title="Recuperar Senha" icon="lock" v-if="!enviado">
      <div>
        <h2 style="font-size: 25px;"><b>Esqueceu a senha ?</b></h2>
        <span style="font-size: 18px;">
          Introduza o email vinculado ao seu login de usuário.
        </span>
      </div>
      <form @submit.prevent="submit" class="mt-2">
        <b-field label="Email">
          <b-input v-model="form.email" name="email" type="email" required />
        </b-field>
        <b-field class="mt-3">
          <div class="buttons">
            <b-button native-type="submit" type="is-primary" :loading="isLoading" expanded :disabled="!form.email">
              Recuperar Senha
            </b-button>
          </div>
        </b-field>
        <b-field>
          <div class="buttons">
            <router-link to="/login" class="button is-outlined is-black" style="width: 100%;">
              Voltar
            </router-link>
          </div>
        </b-field>
      </form>
      <hr>
      <div style="text-align: center;">
        <img src="@/assets/logo-emap.jpg" alt="porto do itaqui" style="width: 150px;">
      </div>
    </card-component>

    <card-component title="Recuperar Senha" icon="lock" v-else>
      <h2 style="font-size: 1.3rem;"><b>Verifique o E-mail.</b></h2>
      <div class="mt-2">
        <span style="font-size: 18px;" v-html="formattedMessage"></span>
      </div>
      <br>
      <div class="control">
        <router-link to="/login" class="button is-outlined is-black" style="width: 100%;">
          Voltar
        </router-link>
      </div>
      <hr>
      <div style="text-align: center;">
        <img src="@/assets/logo-emap.jpg" alt="porto do itaqui" style="width: 150px;">
      </div>
    </card-component>

  </div>
</template>
  
<script>
import { defineComponent } from 'vue'
import CardComponent from '@/components/CardComponent.vue'
import usuarioService from '@/services/UsuarioService'

export default defineComponent({
  name: 'RecuperarSenha',
  components: { CardComponent },
  data() {
    return {
      isLoading: false,
      form: {},
      enviado: false
    }
  },
  mounted() {
  },
  computed: {
    formattedMessage() {
      return `Se tivermos uma conta vinculada com o endereço de email que forneceu <b>(${this.form.email})</b>, enviaremos as instruções para a recuperação da sua senha. As instruções serão válida por 30 minutos.`;
    }
  },
  methods: {
    submit() {
      this.isLoading = true
      if (this.form.email) {
        usuarioService.gerarTokenRecuperarSenha(this.form)
          .then(response => {
          })
          .catch(error => {
            if (error.response) {
              this.$buefy.toast.open({
                message: `Dados incorretos ou inválidos!`,
                position: 'is-bottom',
                type: 'is-danger'
              })
              console.log(error.response)
            }
          });
      }

      setTimeout(() => {
        this.isLoading = false
        this.enviado = true
      }, 750)
    },
  }
})
</script>
  