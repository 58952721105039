<template>
  <aside v-show="isAsideVisible" class="aside is-placed-left">
    <div class="aside-tools">
      <a class="navbar-item is-hidden-touch is-hidden-widescreen is-desktop-icon-only" @click="asideToggleDesktopOnly">
        <b-icon icon="menu" />
      </a>
      <div class="aside-tools-label">
        <span><b><a style="color: inherit;" href="/">SECAPI +</a></b></span>
      </div>
    </div>
    <div class="menu is-menu-main">
      <template v-for="(menuGroup, index) in menu">
        <p v-if="shouldRenderHeader(menuGroup)" :key="`label-${index}`" class="menu-label">
          {{ menuGroup[0].folha }}
        </p>
        <aside-menu-list v-else :key="`menu-${index}`" :menu="menuGroup" @menu-click="menuClick" />
      </template>
    </div>
  </aside>
</template>

<script>
import { defineComponent } from 'vue'
import { mapState } from 'vuex'
import AsideMenuList from '@/components/AsideMenuList.vue'
import storage from '@/mixins/storage'

export default defineComponent({
  name: 'AsideMenu',
  components: { AsideMenuList },
  props: {
    menu: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      perfilUser: storage.methods.getRole(),
    }
  },
  computed: {
    ...mapState([
      'isAsideVisible'
    ]),
  },
  methods: {
    asideToggleDesktopOnly() {
      this.$store.dispatch('asideDesktopOnlyToggle')
    },
    menuClick(item) {
      //
    },

    shouldRenderHeader(menuGroup) {
      if (menuGroup[0].folha) {
        for (const menuItem of menuGroup) {
          if (menuItem.papeis && menuItem.papeis.includes(this.perfilUser)) {
            return true;
          }
        }
      }
      return false;
    },

    
  },
  mounted(){
    //console.log(this.menu)
  }
})
</script>
