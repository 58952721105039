<template>
    <b-modal :active.sync="isModalActive" has-modal-card>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">
            <b>Desligar Funcionário</b>
          </p>
          <button type="button" class="delete" @click="cancel" />
        </header>
        <section class="modal-card-body">

          <form @submit.prevent="buscar" class="columns is-centered">

              <b-field label="Data Desligamento">
                  <b-input type="date" v-model="data" icon="email-outline" placeholder="E-mail*" name="email" required/>
              </b-field>
            </form>

        </section>
        <footer class="modal-card-foot">
          <b-button native-type="button" type="is-danger" outlined @click="cancel">
            Cancelar
          </b-button>
          <b-button type="is-success" @click="confirm">
            Salvar
          </b-button>
        </footer>
      </div>
    </b-modal>
  </template>
    
  <script>
  import { defineComponent } from 'vue'
  
  export default defineComponent({
    name: 'ModalDesligarPessoa',
    props: {
      isActive: Boolean,
      pessoa: Object
    },
    emits: ['cancel', 'confirm'],
    data() {
      return {
          data: null
      }
    },
    computed: {
      isModalActive: {
        get: function () {
          return this.isActive
        },
        set: function (value) {
          if (!value) {
            this.cancel()
          }
        }
      }
    },
    methods: {
      confirm() {
        const pessoa = this.pessoa
        pessoa.dataDesligamento = this.data
        this.$emit('confirm', pessoa)
      },
  
      cancel() {
        this.$emit('cancel')
      }
    }
  })
  </script>
    