<template>
    <b-modal :active.sync="isModalActive" has-modal-card>
        <div class="modal-card">
            <header class="modal-card-head">
                <p class="modal-card-title">
                    <b>Consultar Placa</b>
                </p>
                <button type="button" class="delete" @click="cancel" />
            </header>
            <section class="modal-card-body">
                <card-component>
                    <form @submit.prevent="buscar" class="columns is-centered">

                        <b-field>
                            <b-field label="Consultar Placa">
                                <b-input v-model="placa" icon="magnify" placeholder="Consultar Placa" name="placa" />
                            </b-field>

                            <b-field grouped style="margin-top: 32px;">
                                <div class="control">
                                    <b-tooltip label="Buscar" type="is-link">
                                        <b-button native-type="submit" type="is-info" icon-left="magnify" :disabled="!placa"></b-button>
                                    </b-tooltip>
                                </div>
                            </b-field>
                        </b-field>

                    </form>
                    <br>
                    <div style="width: 400px; margin-left: auto; margin-right: auto;">
                        <b-field v-if="veiculo">
                            <b-message :type="(veiculo.status == 'CADASTRADO') ? 'is-success' : 'is-danger'"
                                aria-close-label="Close message" has-icon>
                                <b>Placa: </b>{{ veiculo.placa }},
                                <br>
                                <b>Estado: </b>{{ veiculo.status }},
                                <br>
                                <b>Area de Acesso: </b><span v-if="veiculo.area == 'PRIMARIA'">ÁREA PRIMÁRIA</span><span
                                    v-else>ÁREA SECUNDÁRIA</span>
                                <br>
                                <b><span v-if="veiculo.status != 'CADASTRADO'">NÃO LIBERADO!</span><span
                                        v-else>LIBERADO!</span></b>
                            </b-message>
                        </b-field>
                        
                        <b-field>
                            <b-message v-model="mostrarMsg" type="is-danger" aria-close-label="Close message" has-icon>
                                <b>Placa:</b> {{ placaVeic }} <br>
                                <b>NÃO ENCONTRADO!</b>
                            </b-message>
                        </b-field>
                    </div>
                </card-component>
            </section>
            <footer class="modal-card-foot">
                <b-button native-type="button" type="is-danger" outlined @click="cancel">
                    Cancelar
                </b-button>
                <b-button type="is-success" @click="confirm">
                    Abrir Cancela
                </b-button>
            </footer>
        </div>
    </b-modal>
</template>
    
<script>
import { defineComponent } from 'vue'
import CardComponent from '@/components/CardComponent.vue'
import MensageConsultaPlaca from '@/components/MensageConsultaPlaca.vue'
import VeiculoService from '@/services/VeiculoService.js'


export default defineComponent({
    name: 'ModalConsultarPlaca',
    components: {
        CardComponent,
        MensageConsultaPlaca
    },
    props: {
        isActive: Boolean,
    },
    data() {
        return {
            placa: null,
            veiculo: null,
            mostrarMsg: false,
            placaVeic: null
        }
    },
    emits: ['cancel', 'confirm'],
    computed: {
        isModalActive: {
            get: function () {
                return this.isActive
            },
            set: function (value) {
                if (!value) {
                    this.cancel()
                }
            }
        },

        /* veiculosFormat() {
            return this.veiculos.filter((veiculo) => {
                if (veiculo.placa == this.placa) {
                    return {
                        "id": veiculo.id,
                        "placa": veiculo.placa,
                        "empresa": veiculo.empresa.razaoSocial,
                        "marca": veiculo.marca,
                        "modelo": veiculo.modelo,
                        "ano": veiculo.anoFabricacao,
                        "status": veiculo.status,
                        "usuario": veiculo.usuario,
                        "area": veiculo.area
                    }
                }
            })
        }, */
    },
    methods: {
        confirm() {
            this.placa = null
            this.placaVeic = null
            this.mostrarMsg = false
            this.veiculo = null
            this.$emit('confirm')
        },

        cancel() {
            this.placa = null
            this.placaVeic = null
            this.mostrarMsg = false
            this.veiculo = null
            this.$emit('cancel')
        },

        buscar() {
            VeiculoService.retornaPlaca(this.placa).then((response) => {
                this.veiculo = response.data;
                this.veiculo.length === 0 ? this.mostrarMsg = true : this.mostrarMsg = false;
                this.placaVeic = this.placa
                this.placa = null
            })
        },
    }
})
</script>
    