import Vue from 'vue';

export default {

  getRoles(page, filtro) {
    if(page == undefined){
      page = 0
    }

    let requisicao = `roles?page=${page}`
    if(filtro){
      requisicao += `${filtro}`
    }
    
    return Vue.prototype.$http.get(requisicao);
  },
  
  getRole(id){
    return Vue.prototype.$http.get(`roles/${id}`);
  },

  postRole(roles){
    return Vue.prototype.$http.post('/roles', roles)
  },
  
  putRole(id, role){
    return Vue.prototype.$http.put(`roles/${id}`, role);
  },

  deleteRole(id){
    return Vue.prototype.$http.delete(`roles/${id}`);
  },

  adicionarRecurso(idRole, idRecurso){
    return Vue.prototype.$http.post(`/roles/${idRole}/adicionarRecurso/${idRecurso}`)
  },

  removerRecurso(idRole, idRecurso){
    return Vue.prototype.$http.post(`/roles/${idRole}/removerRecurso/${idRecurso}`)
  },

  duplicarRole(idRole){
    return Vue.prototype.$http.post(`/roles/${idRole}/duplicar`);
  }
};
