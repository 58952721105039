<template>
    <div>
        <title-bar :title-stack="titleStack" />
        <hero-bar>
            Formulário
            <router-link slot="right" to="/" class="button">
                Dashboard
            </router-link>
        </hero-bar>

        <section class="section is-main-section">
            <form @submit.prevent="formAction" class="box">
                <card-component title="Documentação" icon="ballot">
                    <!-- section documentos upload -->
                    <b-field horizontal>
                        <b-field label="PROAPI" v-if="tipoPessoa != 'VISITANTE'">
                            <file-picker v-model="form.proapi.file" type="is-info" />
                        </b-field>
                        <b-field label="Data Realização do Proapi*" style="max-width: 300px;"
                            v-if="tipoPessoa != 'VISITANTE'">
                            <b-datepicker v-model="form.proapi.data" placeholder="dd/mm/aaaa" icon="calendar-today"
                                trap-focus :required="true">
                            </b-datepicker>
                        </b-field>
                    </b-field>

                    <b-field horizontal>
                        <b-field label="ASO" v-if="tipoPessoa != 'VISITANTE'">
                            <file-picker v-model="form.aso.file" type="is-info"/>
                        </b-field>
                        <b-field label="Data Realização do ASO*" style="max-width: 300px;" v-if="tipoPessoa != 'VISITANTE'">
                            <b-datepicker v-model="form.aso.data" placeholder="dd/mm/aaaa" icon="calendar-today" trap-focus
                                :required="true">
                            </b-datepicker>
                        </b-field>
                    </b-field>

                    <!---<b-field horizontal>
                        <b-field label="Comprovante de Vacina">
                            <file-picker v-model="form.vacina.file" type="is-info" :required="true" />
                        </b-field>
                        <b-field label="Data Comprovante Vacina*" style="max-width: 300px;">
                            <b-datepicker v-model="form.vacina.data" placeholder="dd/mm/aaaa" icon="calendar-today"
                                trap-focus :required="true">
                            </b-datepicker>
                        </b-field>
                    </b-field>-->

                    <br>

                    <b-field horizontal>
                        <b-field>
                            <div class="control">
                                <b-button native-type="submit" type="is-info" :loading="isLoading">
                                    Enviar
                                </b-button>
                            </div>
                        </b-field>
                    </b-field>

                    <br>

                    <b-field horizontal>
                        <b-progress v-if="isLoading" size="is-medium" type="is-info" show-value>
                            Enviando arquivos...
                        </b-progress>
                    </b-field>

                </card-component>
            </form>
        </section>
    </div>
</template>

<script>
import { defineComponent } from 'vue'
import TitleBar from '@/components/TitleBar.vue'
import HeroBar from '@/components/HeroBar.vue'
import CardComponent from '@/components/CardComponent.vue'
import DocumentoService from '@/services/DocumentoService'
import FilePicker from '@/components/FilePicker.vue'

export default defineComponent({
    name: 'FormDocs',
    components: {
        TitleBar,
        HeroBar,
        CardComponent,
        FilePicker,
    },

    data() {
        return {
            titleStack: ['SECAPI', 'Pessoas', 'Documentação'],
            form: {
                proapi: {
                    file: null,
                    data: null,
                    tipo: 'PROAPI',
                    idPessoa: this.$route.params.idPessoa
                },
                aso: {
                    file: null,
                    data: null,
                    tipo: 'ASO',
                    idPessoa: this.$route.params.idPessoa
                },
                vacina: {
                    file: null,
                    data: null,
                    tipo: 'COMPROVANTE_VACINA',
                    idPessoa: this.$route.params.idPessoa
                }
            },
            tipoPessoa: this.$route.query.tipoPessoa,
            isLoading: false,
            tiposDocumentos: ['proapi', 'aso']
        }
    },

    methods: {
        async formAction() {
            try {
                this.isLoading = true;

                for (const tipoDocumento of this.tiposDocumentos) {
                    if (tipoDocumento) {
                        const documento = this.form[tipoDocumento];
                        if (documento.file && !documento.data) {
                            this.$buefy.toast.open({
                                message: "Data do " + documento.tipo + " é obrigatório",
                                position: 'is-bottom',
                                type: 'is-danger'
                            })

                            return;
                        }
                    }   
                }

                for (const tipoDocumento of this.tiposDocumentos) {
                    if (tipoDocumento) {
                        const documento = this.form[tipoDocumento];
                        if (documento.file) {
                            await DocumentoService.postDocumento(documento);
                        }
                       
                    }   
                }

                setTimeout(() => {

                    this.$buefy.toast.open({
                        message: 'Documentos Enviados com Sucesso!',
                        position: 'is-bottom',
                        type: 'is-success'
                    })
                    //'/pessoas?page=1&tipoPessoa=MOTORISTA&orderBy=id,DESC'
                    this.$router.push(`/pessoas?page=1&tipoPessoa=${this.tipoPessoa}&orderBy=id,DESC`)
                }, 2000)

            } catch (error) {
                this.$buefy.toast.open({
                    message: error.response.data.message ?? "Erro ao enviar o documento",
                    position: 'is-bottom',
                    type: 'is-danger'
                })
                //console.error('Erro ao enviar documentos:', error);
            } finally {
                this.isLoading = false;
            }
        },
    },

    mounted() {
        if (!this.$route.params.idPessoa) {
            this.$buefy.snackbar.open(
                {
                    message: 'Erro: Não foi possível recuperar o Cadastro !',
                    indefinite: true,
                    type: 'is-danger',
                    actionText: 'Voltar',
                    onAction: () => {
                        this.$router.push('/')
                    }
                }
            )
        }
    }
})
</script>