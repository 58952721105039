<template>
  <section class="hero is-hero-bar">
    <div class="hero-body">
      <div class="level">
        <div class="level-left">
          <div class="level-item">
            <h1 class="title">
              <p class="card-header-title">
                <b-icon
                  v-if="icon"
                  :icon="icon"
                  custom-size="default"
                />
                &nbsp<slot />
              </p>
            </h1>
          </div>
        </div>
        <div
          v-show="hasRightVisible"
          class="level-right"
        >
          <div class="level-item">
            <slot name="right" />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'HeroBar',
  props: {
    hasRightVisible: {
      type: Boolean,
      default: true
    },
    icon: {
      type: String,
      default: null
    },
  }
})
</script>
