<template>
    <div>
        <title-bar :title-stack="titleStack" />
        <div class="card">
            <header class="card-header">
                <p class="card-header-title"><span class="icon"><i class="mdi mdi-ballot default"></i></span> Listar
                    Usuários </p>
                <router-link slot="right" :to="`/adicionarUsuario`" class="button"
                    style="margin-right: 1.5rem; margin-top: 1rem; margin-bottom: 1rem;">
                    Adicionar
                </router-link>
            </header>

            <div class="card-content">
                <form @submit.prevent="buscar()">
                    <b-field horizontal style="margin-left: -200px;">
                        <b-field label="Empresa">
                            <b-input v-model="filtro.empresa" icon="card-bulleted-outline" placeholder="Digite a Empresa"
                                name="empresa" />
                        </b-field>
                        <b-field label="Nome">
                            <b-input v-model="filtro.nome" icon="card-bulleted-outline" placeholder="Digite o Nome"
                                name="nome" />
                        </b-field>
                        <b-field label="CPF">
                            <b-input v-model="filtro.cpf" icon="card-bulleted-outline" placeholder="Digite o CPF"
                                name="cpf" />
                        </b-field>
                        <b-field grouped>
                            <div class="control" style="margin-top: 32px;">
                                <b-button native-type="submit" type="is-info">
                                    Buscar
                                </b-button>
                            </div>
                        </b-field>
                    </b-field>
                </form>
            </div>

            <section class="section is-main-section">
                <card-component class="has-table has-mobile-sort-spaced">
                    <modal-desativar-usuario :is-active="isModalActive"
                        :trash-object-name="trashObject ? trashObject.nome : null" @confirm="trashConfirm"
                        @cancel="trashCancel" />

                    <br>
                    <b-table :checked-rows.sync="checkedRows" :data="usuariosTable" default-sort="name" striped hoverable>
                        <b-table-column v-slot="usuario" label="Nome" field="nome" sortable>
                            {{ usuario.row.nome }}
                        </b-table-column>
                        <b-table-column v-slot="usuario" label="Usuário" field="username" sortable>
                            {{ usuario.row.login }}
                        </b-table-column>
                        <b-table-column v-slot="usuario" label="Empresa" field="empresa" sortable>
                            {{ usuario.row.empresa }}
                        </b-table-column>
                        <b-table-column v-slot="usuario" label="Ações" custom-key="actions" cell-class="is-actions-cell">
                            <div class="buttons is-right no-wrap">
                                <b-tooltip label="Editar" type="is-light ">
                                    <router-link :to="{ name: 'FormUsuario.edit', params: { id: usuario.row.id } }"
                                        class="button is-small is-info">
                                        <b-icon icon="pencil" size="is-small" />
                                    </router-link>
                                </b-tooltip>
                                <b-tooltip label="Desativar" type="is-light ">
                                    <b-button type="is-danger" size="is-small" @click.prevent="trashModalOpen(usuario.row)">
                                        <b-icon icon="cancel" size="is-small" />
                                    </b-button>
                                </b-tooltip>
                            </div>
                        </b-table-column>

                        <section slot="empty" class="section">
                            <div class="content has-text-grey has-text-centered">
                                <p>
                                    <b-icon icon="emoticon-sad" size="is-large" />
                                </p>
                                <p>Não há dados&hellip;</p>
                            </div>
                        </section>
                    </b-table>
                    <br>
                    <section style="margin:15px;">
                        <b-pagination :total="pageable.size" v-model="pageable.current" :range-before="3" :range-after="1"
                            :order="''" :size="''" :simple="false" :rounded="false" :per-page="15"
                            :icon-prev="'chevron-left'" :icon-next="'chevron-right'" aria-next-label="Next page"
                            aria-previous-label="Previous page" aria-page-label="Page" aria-current-label="Current page"
                            :page-input="false" :page-input-position="''" :debounce-page-input="''">

                            <template #default="props">
                                <b-pagination-button :page="props.page" :id="`page${props.page.number}`" tag="router-link"
                                    :to="`/usuarios?page=${props.page.number}&${$util.formatQueryUrl($route.query)}`">
                                    {{ props.page.number }}
                                </b-pagination-button>
                            </template>


                            <template #previous="props">
                                <b-pagination-button :page="props.page" tag="router-link"
                                    :to="`/usuarios?page=${props.page.number}&${$util.formatQueryUrl($route.query)}`">
                                    Anterior
                                </b-pagination-button>
                            </template>

                            <template #next="props">
                                <b-pagination-button :page="props.page" tag="router-link"
                                    :to="`/usuarios?page=${props.page.number}&${$util.formatQueryUrl($route.query)}`">
                                    Próximo
                                </b-pagination-button>
                            </template>
                        </b-pagination>
                        <template>
                            <div>
                                <b>Total de Registros: </b> {{ totalElements }}
                            </div>
                        </template>
                    </section>
                </card-component>
            </section>
        </div>
    </div>
</template>
<script>

import { defineComponent } from 'vue'
import TitleBar from '@/components/TitleBar.vue'
import CardComponent from '@/components/CardComponent.vue'
import ModalDesativarUsuario from '@/components/ModalDesativarUsuario.vue'
import UsuarioService from '@/services/UsuarioService.js'

export default defineComponent({
    name: "ListUsuario",
    components: {
        TitleBar,
        CardComponent,
        ModalDesativarUsuario
    },
    data() {
        return {
            titleStack: ['SECAPI', 'Configurações', 'Listar Usuários'],
            filtro: {},
            isModalActive: false,
            trashObject: null,
            checkedRows: [],
            usuarios: [],
            pageable: {
                current: 1,
                size: 0,
            },
            totalElements: null,
            totalPages: null,
            size: null,
        }
    },
    mounted() {
    },
    methods: {
        buscar() {
            if (this.filtro) {
                this.pageable.current = 1
                let queryFiltro = `usuarios?page=${this.pageable.current}${this.$util.formatQueryUrl(this.filtro)}&orderBy=id,DESC`
                this.$router.push(queryFiltro)
            }
        },
        trashConfirm() {
            this.isModalActive = false
            const id = this.trashObject.id

            UsuarioService.deleteUsuario(id).then(() => {
                this.$buefy.toast.open({
                    message: `Veículo Deletado!`,
                    position: 'is-bottom',
                    type: 'is-info'
                })
                location.reload();
            }).catch((error) => {
                this.$buefy.toast.open({
                    message: `Houve algum Problema ao deletar Veículo`,
                    position: 'is-bottom',
                    type: 'is-danger'
                })
                console.error(error)
            })
        },
        trashCancel() {
            this.isModalActive = false
        },
        trashModalOpen(obj) {
            this.trashObject = obj
            this.isModalActive = true
        },
    },
    computed: {
        usuariosTable() {
            return this.usuarios.map((usuario) => {
                return {
                    "id": usuario.id,
                    "nome": usuario.nome,
                    "login": usuario.login,
                    "empresa": usuario.empresa.razaoSocial,
                }
            })
        },
    },
    watch: {
        $route: {
            immediate: true,
            handler(newVal, oldVal) {
                if (newVal.query) {
                    this.pageable.current = parseInt(newVal.query.page)

                    UsuarioService.getUsuarios(this.pageable.current - 1,  this.$util.formatQueryUrl(this.$route.query)).then((response) => {
                        this.usuarios = response.data.content;
                        this.pageable.current = response.data.number + 1;
                        this.pageable.size = response.data.totalElements;
                        this.totalPages = response.data.totalPages;
                        this.totalElements = response.data.totalElements;
                    })

                    this.filtro = {}
                }
            },
        },
    }
})
</script>