<template>
  <div>
    <hero-bar :has-right-visible="false">
      Dashboard
    </hero-bar>

    <section class="section is-main-section">

      <div>
        <p style="font-size:25px;"><b>Funcionários</b></p>
      </div>

      <div class="tile is-ancestor">

        <div class="tile is-parent">
          <div class="card tile is-child has-background-primary has-background-danger"><!---->
            <div class="card-content">
              <div class="level is-mobile">
                <div class="level-item">
                  <div class="is-widget-label">
                    <h3 class="subtitle is-spaced has-text-dark"> Pendentes </h3>
                    <h1 class="title">
                      <div>{{ infoCards.funcPendente }}</div>
                    </h1>
                  </div>
                </div>
                <div class="level-item has-widget-icon">
                  <div class="is-widget-icon"><span class="icon has-text-danger-dark is-large">
                      <i class="mdi mdi-account-tie mdi-48px"></i></span>
                  </div>
                </div>
              </div>
            </div>
            <footer class="card-footer">
              <router-link class="card-footer-item"
                :to="`pessoas?page=1&tipoPessoa=FUNCIONARIO&status=PENDENTE&orderBy=id,DESC`">
                <i class="mdi mdi-plus-circle mdi-10px mr-1"></i>
                <span>Mais Informações </span>
              </router-link>
            </footer>
          </div>
        </div>

        <div class="tile is-parent">
          <div class="card tile is-child has-background-primary"><!---->
            <div class="card-content">
              <div class="level is-mobile">
                <div class="level-item">
                  <div class="is-widget-label">
                    <h3 class="subtitle is-spaced  has-text-dark"> Documentação Expirada </h3>
                    <h1 class="title">
                      <div>{{ infoCards.funcDocumentacaoExpirada }}</div>
                    </h1>
                  </div>
                </div>
                <div class="level-item has-widget-icon">
                  <div class="is-widget-icon"><span class="icon has-text-primary-dark is-large">
                      <i class="mdi mdi-account-tie mdi-48px"></i></span></div>
                </div>
              </div>
            </div>
            <footer class="card-footer">
              <router-link class="card-footer-item"
                :to="`pessoas?page=1&tipoPessoa=FUNCIONARIO&status=DOCUMENTACAO_EXPIRADA&orderBy=id,DESC`">
                <i class="mdi mdi-plus-circle mdi-10px mr-1"></i>
                <span>Mais Informações </span>
              </router-link>
            </footer>
          </div>
        </div>

        <div class="tile is-parent">
          <div class="card tile is-child has-background-primary"><!---->
            <div class="card-content">
              <div class="level is-mobile">
                <div class="level-item">
                  <div class="is-widget-label">
                    <h3 class="subtitle is-spaced has-text-dark"> Pendente de Remoção </h3>
                    <h1 class="title">
                      <div>{{ infoCards.funcPendenteRemocao }}</div>
                    </h1>
                  </div>
                </div>
                <div class="level-item has-widget-icon">
                  <div class="is-widget-icon"><span class="icon has-text-primary-dark is-large">
                      <i class="mdi mdi-account-tie mdi-48px"></i></span></div>
                </div>
              </div>
            </div>
            <footer class="card-footer">
              <router-link class="card-footer-item"
                :to="`pessoas?page=1&tipoPessoa=FUNCIONARIO&status=PENDENTE_REMOCAO&orderBy=id,DESC`">
                <i class="mdi mdi-plus-circle mdi-10px mr-1"></i>
                <span>Mais Informações </span>
              </router-link>
            </footer>
          </div>

        </div>

      </div>

      <div>
        <p style="font-size:25px;"><b>Veículos</b></p>
      </div>
      <div class="tile is-ancestor">
        <div class="tile is-parent">

          <div class="card tile is-child has-background-danger"><!---->
            <div class="card-content">
              <div class="level is-mobile">
                <div class="level-item">
                  <div class="is-widget-label">
                    <h3 class="subtitle is-spaced has-text-dark"> Pendentes Área primária </h3>
                    <h1 class="title">
                      <div>{{ infoCards.veicPendentePrimaria }}</div>
                    </h1>
                  </div>
                </div>
                <div class="level-item has-widget-icon">
                  <div class="is-widget-icon"><span class="icon has-text-danger-dark is-large">
                      <i class="mdi mdi-car-back mdi-48px"></i></span></div>
                </div>
              </div>
            </div>
            <footer class="card-footer">
              <router-link class="card-footer-item" :to="`veiculos?page=1&area=PRIMARIA&status=PENDENTEA&classificacao=AUTOMOVEL&orderBy=id,DESC`">
                <i class="mdi mdi-plus-circle mdi-10px mr-1"></i>
                <span>Mais Informações </span>
              </router-link>
            </footer>
          </div>
        </div>
        <div class="tile is-parent">

          <div class="card tile is-child has-background-primary"><!---->
            <div class="card-content">
              <div class="level is-mobile">
                <div class="level-item">
                  <div class="is-widget-label">
                    <h3 class="subtitle is-spaced has-text-dark"> Pendentes Área secundária </h3>
                    <h1 class="title">
                      <div>{{ infoCards.veicPendenteSecundaria }}</div>
                    </h1>
                  </div>
                </div>
                <div class="level-item has-widget-icon">
                  <div class="is-widget-icon"><span class="icon has-text-primary-dark is-large">
                      <i class="mdi mdi-car-back mdi-48px"></i></span></div>
                </div>
              </div>
            </div>
            <footer class="card-footer">
              <router-link class="card-footer-item"
                :to="`veiculos?page=1&area=SECUNDARIA&status=PENDENTEA&classificacao=AUTOMOVEL&orderBy=id,DESC`">
                <i class="mdi mdi-plus-circle mdi-10px mr-1"></i>
                <span>Mais Informações </span>
              </router-link>
            </footer>
          </div>
        </div>
        <div class="tile is-parent">

          <div class="card tile is-child has-background-primary"><!---->
            <div class="card-content">
              <div class="level is-mobile">
                <div class="level-item">
                  <div class="is-widget-label">
                    <h3 class="subtitle is-spaced has-text-dark"> Pendentes Avulso </h3>
                    <h1 class="title">
                      <div>{{ infoCards.veicPendenteAvulso }}</div>
                    </h1>
                  </div>
                </div>
                <div class="level-item has-widget-icon">
                  <div class="is-widget-icon"><span class="icon has-text-primary-dark is-large">
                      <i class="mdi mdi-car-back mdi-48px"></i></span></div>
                </div>
              </div>
            </div>
            <footer class="card-footer">
              <router-link class="card-footer-item"
                :to="`veiculos?page=1&tipo=AVULSO&status=PENDENTE&orderBy=id,DESC`">
                <i class="mdi mdi-plus-circle mdi-10px mr-1"></i>
                <span>Mais Informações </span>
              </router-link>
            </footer>
          </div>
        </div>
      </div>


      <div>
        <p style="font-size:25px;"><b>Visitantes</b></p>
      </div>
      <div class="tile is-ancestor">
        <div class="tile is-parent">

          <div class="card tile is-child has-background-danger"><!---->
            <div class="card-content">
              <div class="level is-mobile">
                <div class="level-item">
                  <div class="is-widget-label">
                    <h3 class="subtitle is-spaced has-text-dark"> Pendentes </h3>
                    <h1 class="title">
                      <div>{{ infoCards.visitantePendente }}</div>
                    </h1>
                  </div>
                </div>
                <div class="level-item has-widget-icon">
                  <div class="is-widget-icon"><span class="icon has-text-danger-dark is-large">
                      <i class="mdi mdi-account-group mdi-48px"></i></span></div>
                </div>
              </div>
            </div>
            <footer class="card-footer">
              <router-link class="card-footer-item"
                :to="`pessoas?page=1&tipoPessoa=VISITANTE&status=PENDENTE&orderBy=id,DESC`">
                <i class="mdi mdi-plus-circle mdi-10px mr-1"></i>
                <span>Mais Informações </span>
              </router-link>
            </footer>
          </div>
        </div>
        <div class="tile is-parent">

          <div class="card tile is-child has-background-primary"><!---->
            <div class="card-content">
              <div class="level is-mobile">
                <div class="level-item">
                  <div class="is-widget-label">
                    <h3 class="subtitle is-spaced has-text-dark"> Cadastrados </h3>
                    <h1 class="title">
                      <div>{{ infoCards.visitanteCadastrado }}</div>
                    </h1>
                  </div>
                </div>
                <div class="level-item has-widget-icon">
                  <div class="is-widget-icon"><span class="icon has-text-primary-dark is-large">
                      <i class="mdi mdi-account-group mdi-48px"></i></span></div>
                </div>
              </div>
            </div>
            <footer class="card-footer">
              <router-link class="card-footer-item"
                :to="`pessoas?page=1&tipoPessoa=VISITANTE&status=CADASTRADO&orderBy=id,DESC`">
                <i class="mdi mdi-plus-circle mdi-10px mr-1"></i>
                <span>Mais Informações </span>
              </router-link>
            </footer>
          </div>
        </div>
        <div class="tile is-parent">


        </div>
      </div>



      <div>
        <p style="font-size:25px;"><b>Motocicleta</b></p>
      </div>
      <div class="tile is-ancestor">
        <div class="tile is-parent">

          <div class="card tile is-child has-background-danger"><!---->
            <div class="card-content">
              <div class="level is-mobile">
                <div class="level-item">
                  <div class="is-widget-label">
                    <h3 class="subtitle is-spaced has-text-dark"> Pendentes </h3>
                    <h1 class="title">
                      <div>{{ infoCards.motocicletaPendente }}</div>
                    </h1>
                  </div>
                </div>
                <div class="level-item has-widget-icon">
                  <div class="is-widget-icon"><span class="icon has-text-danger-dark is-large">
                      <i class="mdi mdi-motorbike mdi-48px"></i></span></div>
                </div>
              </div>
            </div>
            <footer class="card-footer">
              <router-link class="card-footer-item"
                :to="`veiculos?page=1&classificacao=MOTOCICLETA&status=PENDENTE&orderBy=id,DESC`">
                <i class="mdi mdi-plus-circle mdi-10px mr-1"></i>
                <span>Mais Informações </span>
              </router-link>
            </footer>
          </div>
        </div>
        <div class="tile is-parent">
          <div class="card tile is-child has-background-primary"><!---->
            <div class="card-content">
              <div class="level is-mobile">
                <div class="level-item">
                  <div class="is-widget-label">
                    <h3 class="subtitle is-spaced  has-text-dark"> Cadastrados </h3>
                    <h1 class="title">
                      <div>{{ infoCards.motocicletaCadastrado }}</div>
                    </h1>
                  </div>
                </div>
                <div class="level-item has-widget-icon">
                  <div class="is-widget-icon"><span class="icon has-text-primary-dark is-large">
                      <i class="mdi mdi-motorbike mdi-48px"></i></span></div>
                </div>
              </div>
            </div>
            <footer class="card-footer">
              <router-link class="card-footer-item"
                :to="`veiculos?page=1&classificacao=MOTOCICLETA&status=CADASTRADO&orderBy=id,DESC`">
                <i class="mdi mdi-plus-circle mdi-10px mr-1"></i>
                <span>Mais Informações </span>
              </router-link>
            </footer>
          </div>
        </div>
        <div class="tile is-parent">

        </div>
      </div>
      <br>

    </section>
  </div>
</template>

<style>
.card-footer-item {
  padding: 0.1rem;
  color: rgb(65, 65, 65);
}

.card-footer-item:hover{
  color: rgb(0, 0, 0);
}

.card-footer{
  border-top: 1px solid white;
}

.card-footer:hover{
  background-color: rgba(0, 0, 0, 0.113);
}
</style>

<script>
import { defineComponent } from 'vue'
import * as chartConfig from '@/components/Charts/chart.config.js'
import TitleBar from '@/components/TitleBar.vue'
import HeroBar from '@/components/HeroBar.vue'
import TilesBlock from '@/components/TilesBlock.vue'
import CardComponent from '@/components/CardComponent.vue'
import LineChart from '@/components/Charts/LineChart.vue'

export default defineComponent({
  name: 'HomeView',
  components: {
    LineChart,
    CardComponent,
    TilesBlock,
    HeroBar,
    TitleBar
  },
  data() {
    return {
      titleStack: ['SECAPI', 'Dashboard'],
      chartData: null,
      teste: null,
      infoCards: []
    }
  },
  mounted() {
    this.$http.get('/dashboard').then((response) => {
      this.infoCards = response.data;
    })

    this.fillChartData()

    /*this.$buefy.snackbar.open({
      message: 'Bem vindo de volta!',
      queue: false
    })*/
  },
  methods: {
    fillChartData() {
      this.chartData = chartConfig.sampleChartData()
    },

  }
})
</script>
