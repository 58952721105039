import Vue from 'vue';

export default {

  getUsuarios(page, filtro) {
    if(page == undefined){
      page = 0
    }

    let requisicao = `usuarios?page=${page}`
    if(filtro){
      requisicao += `${filtro}`
    }
    
    return Vue.prototype.$http.get(requisicao);
  },
  
  getUsuario(id){
    return Vue.prototype.$http.get(`usuarios/${id}`);
  },

  postUsuario(usuario){
    return Vue.prototype.$http.post('/usuarios', usuario)
  },
  
  putUsuario(id, usuario){
    return Vue.prototype.$http.put(`usuarios/${id}`, usuario);
  },

  deleteUsuario(id){
    return Vue.prototype.$http.delete(`usuarios/${id}`);
  },

  gerarTokenRecuperarSenha(body){
    return Vue.prototype.$http.post(`usuarios/token`, body)
  },
  
  resetSenha(body){
    return Vue.prototype.$http.post(`usuarios/resetSenha`, body)
  },

  alterarSenha(body){
    return Vue.prototype.$http.post(`usuarios/alterarSenha`, body)
  }

};
