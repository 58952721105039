import Vue from 'vue';

export default {
    postAutorizacao(form) {
        form.inicio = Vue.prototype.$util.formatarData(form.inicio);
        return Vue.prototype.$http.post('/autorizacoes', form);
    },
    getAutorizacao(page, filtro) {
        if (page == undefined) {
            page = 0
        }
        var request = `autorizacoes?page=${page}`
        if(filtro){
            request += `${filtro}`
        }
        return Vue.prototype.$http.get(request);
    },
    cancelarAutorizacao(id){
        return Vue.prototype.$http.put(`/autorizacoes/${id}/cancelar`);
    },
    getAutorizacaoById(id){
        return Vue.prototype.$http.get(`/autorizacoes/${id}`);
    },
    receberAutorizacao(id){
        return Vue.prototype.$http.put(`/autorizacoes/${id}/receber`);
    },
    darEntradaAutorizacao(id){
        return Vue.prototype.$http.put(`/autorizacoes/${id}/entrada`);
    }
}